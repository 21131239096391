import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, ModalBody, Row } from "react-bootstrap";
import {
  getPropertydetail,
  getVerifiedPropertydetail,
  getPropertydetailByID,
  useQuery,
  getVerifyPropertyByID,
} from "../../config/api";

function VerifiedpropertyDetails() {
  const [modalShow, setModalShow] = React.useState(false);
  let query = useQuery();
  let id = query.get("id");
  let type = query.get("t");
  const [data, setData] = useState([]);
  const [propertydata, setPropertyData] = useState({});
  const [verifiedpropertydata, setVerifiedPropertyData] = useState();
  useEffect(() => {
    //check_islogin();
    bindData();
  }, []);
  async function bindData() {
    await getVerifiedPropertydetail({ ID: id })
      .then((response) => {
        if (response.length > 0) {
          setData(response);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }
  console.log(propertydata);
  async function bindDatabyid(pid) {
    await getPropertydetailByID({ PropertydetailID: pid })
      .then((response) => {
        if (response.length > 0) {
          setPropertyData(response[0]);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  return (
    <>
      <div>
        <h4 className="fw-bold py-3 mb-4">
          <span className="text-muted fw-light">Home/</span>
          <span className="text-muted fw-light">{type} User Details/</span>
          {type} Property Details
        </h4>
        <Row>
          {data
            ? data.map((item, idx) => (
                <Col lg={4} md={12} sm={12}>
                  <div className="prop-bg-color">
                    <div className="prop-card">
                      <div className="flex">
                        <p>PROPERTY NAME :</p>
                        <p>{item.Name}</p>
                      </div>
                      {item.minPrice !== "" ? (
                        <div className="flex">
                          <p>MINIMUM PRICE :</p>
                          <p>₹ {item.minPrice}</p>
                        </div>
                      ) : (
                        ""
                      )}
                      {item.maxPrice !== "" ? (
                        <div className="flex">
                          <p>MAXIMUM PRICE :</p>
                          <p>₹ {item.maxPrice}</p>
                        </div>
                      ) : (
                        ""
                      )}
                      {item.rentalPrice !== "" ? (
                        <div className="flex">
                          <p>RENTAL PRICE :</p>
                          <p>₹ {item.rentalPrice}</p>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="flex">
                        <p>SIZE :</p>
                        <p>{item.PropertySize}</p>
                      </div>
                      <div className="view-verify">
                        <p
                          className="cursor-pointer"
                          onClick={() => {
                            bindDatabyid(item.PropertydetailID);
                            setModalShow(true);
                          }}
                        >
                          View More
                        </p>
                        {/* <p href="/">Verify</p> */}
                      </div>
                    </div>
                  </div>
                </Col>
              ))
            : ""}
          {/* <Col lg={4} md={12} sm={12}>
            <div className="prop-bg-color">
              <div className="prop-card">
                <div className="flex">
                  <p>PROPERTY NAME :</p>
                  <p>Green Crest Taloja</p>
                </div>
                <div className="flex">
                  <p>PRICE :</p>
                  <p>₹ 86,00,000</p>
                </div>
                <div className="flex">
                  <p>SIZE :</p>
                  <p>5000 sq / ft</p>
                </div>
                <div className="flex">
                  <p>BHK TYPE :</p>
                  <p>3 BHK</p>
                </div>
                <div className="view-verify">
                  <p
                    className="cursor-pointer"
                    onClick={() => setModalShow(true)}
                  >
                    View More
                  </p>
                  <p>Verify</p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={12} sm={12}>
            <div className="prop-bg-color">
              <div className="prop-card">
                <div className="flex">
                  <p>PROPERTY NAME :</p>
                  <p>Green Crest Taloja</p>
                </div>
                <div className="flex">
                  <p>PRICE :</p>
                  <p>₹ 86,00,000</p>
                </div>
                <div className="flex">
                  <p>SIZE :</p>
                  <p>5000 sq / ft</p>
                </div>
                <div className="flex">
                  <p>BHK TYPE :</p>
                  <p>3 BHK</p>
                </div>
                <div className="view-verify">
                  <p
                    className="cursor-pointer"
                    onClick={() => setModalShow(true)}
                  >
                    View More
                  </p>
                  <p href="/">Verify</p>
                </div>
              </div>
            </div>
          </Col> */}
          <PropBlog
            show={modalShow}
            onHide={() => setModalShow(false)}
            propertydata={propertydata}
          />
        </Row>
      </div>
    </>
  );
}

function PropBlog(props) {
  const d = props.propertydata ? props.propertydata : [];
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Property Details
          </Modal.Title>
        </Modal.Header>
        <hr />
        <ModalBody>
          <div className="seller-prop">
            <div className="row">
              <h5>Property Details</h5>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="">
                  <p className="mb-0">PROPERTY NAME :</p>
                  <p>{d.Name}</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.minPrice !== "" ? (
                  <div className="">
                    <p className="mb-0">MINIMUM PRICE :</p>
                    <p>{d.minPrice}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.maxPrice !== "" ? (
                  <div className="">
                    <p className="mb-0">MAXIMUM PRICE :</p>
                    <p>{d.maxPrice}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.PropertyType && d.PropertyType !== "" ? (
                  <div className="">
                    <p className="mb-0">PROPERTY TYPE :</p>
                    <p>{d.PropertyType}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.PropertySize !== "" ? (
                  <div className="">
                    <p className="mb-0">SIZE :</p>
                    <p>{d.PropertySize}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.rentalPrice && d.rentalPrice !== "" ? (
                  <div className="">
                    <p className="mb-0">RENTAL PRICE :</p>
                    <p>{d.rentalPrice}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.Availability && d.Availability !== "" ? (
                  <div className="">
                    <p className="mb-0">AVAILABILITY :</p>
                    <p>{d.Availability}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.ConstructionStatus && d.ConstructionStatus !== "" ? (
                  <div className="">
                    <p className="mb-0">CONSTRUCTION STATUS :</p>
                    <p>{d.ConstructionStatus}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.Furnishing && d.Furnishing !== "" ? (
                  <div className="">
                    <p className="mb-0">FURNISHING :</p>
                    <p>{d.Furnishing}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.YearBuilt && d.YearBuilt !== "" ? (
                  <div className="">
                    <p className="mb-0">YEAR BUILT :</p>
                    <p>{d.YearBuilt}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-8 col-md-6 col-sm-12">
                {d.Specification && d.Specification !== "" ? (
                  <div className="">
                    <p className="mb-0">SPECIFICATION :</p>
                    <p>{d.Specification}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <hr />
            <div className="row">
              <h5>Locality Details</h5>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.Address && d.Address !== "" ? (
                  <div className="">
                    <p className="mb-0">ADDRESS :</p>
                    <p>{d.Address}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.SocietyName && d.SocietyName !== "" ? (
                  <div className="">
                    <p className="mb-0">SOCIETY NAME :</p>
                    <p>{d.SocietyName}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.Area && d.Area !== "" ? (
                  <div className="">
                    <p className="mb-0">AREA :</p>
                    <p>{d.Area}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.CountryName && d.CountryName !== "" ? (
                  <div className="">
                    <p className="mb-0">COUNTRY NAME :</p>
                    <p>{d.CountryName}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.StateName && d.StateName !== "" ? (
                  <div className="">
                    <p className="mb-0">STATE NAME :</p>
                    <p>{d.StateName}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.CityName && d.CityName !== "" ? (
                  <div className="">
                    <p className="mb-0">CITY NAME :</p>
                    <p>{d.CityName}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.Pincode && d.Pincode !== "" ? (
                  <div className="">
                    <p className="mb-0">PINCODE :</p>
                    <p>{d.Pincode}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <hr />
            <div className="row">
              <h5>Property Features</h5>{" "}
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.Bedroom && d.Bedroom !== "" ? (
                  <div className="flex">
                    <p className="mb-0">BEDROOM :</p>
                    <p>{d.Bedroom}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.Bathroom && d.Bathroom !== "" ? (
                  <div className="flex">
                    <p className="mb-0">BATHROOM :</p>
                    <p>{d.Bathroom}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.Balcony && d.Balcony !== "" ? (
                  <div className="flex">
                    <p className="mb-0">BALCONY :</p>
                    <p>{d.Balcony}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.CompletionMonth && d.CompletionMonth !== "" ? (
                  <div className="flex">
                    <p className="mb-0">COMPLETION :</p>
                    <p>{d.CompletionMonth - d.CompletionYear}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.Flats && d.Flats !== "" ? (
                  <div className="flex">
                    <p className="mb-0">FLATS :</p>
                    <p>{d.Flats}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.Floor && d.Floor !== "" ? (
                  <div className="flex">
                    <p className="mb-0">FLOOR :</p>
                    <p>{d.Floor}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.Parking && d.Parking !== "" ? (
                  <div className="flex">
                    <p className="mb-0">PARKING :</p>
                    <p>{d.Parking}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {d.PlanType && d.PlanType !== "" ? (
                  <div className="flex">
                    <p className="mb-0">PLAN TYPE :</p>
                    <p>{d.PlanType}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <hr />
          <h4>Other Featurs</h4>
          <Row>
            {d.OtherFeature
              ? d.OtherFeature.split(",").map((item, idx) => (
                  <Col lg={4} md={12} sm={12} key={idx}>
                    <p>{item}</p>
                  </Col>
                ))
              : ""}
            {/* <Col lg={4} md={12} sm={12}>
              <p>Lawn</p>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <p>Gym</p>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <p>Swimming Pool</p>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <p>Meditation Zone</p>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <p>Outdoor & Indoor Spoarts Area</p>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <p>Spa & Steam room</p>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <p>Library & Classroom</p>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <p>Party Lawn & Hall</p>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <p>Lounge, Theater or Cafeteria</p>
            </Col> */}
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}

export default VerifiedpropertyDetails;
