import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "bs-stepper/dist/css/bs-stepper.min.css";
import BsStepperStyle from "../helper/BsStepperStyle";
import { Link, Outlet } from "react-router-dom";
export default function PackageAdd() {
  const [activeIndex, setActiveIndex] = useState(0);
  let location = useLocation();
  const stepper = [
    {
      route: "/app/listing/add/property-details",
      title: "Property Details",
      subTitle: "Property Details",
    },
    {
      route: "/app/listing/add/locality-details",
      title: "Locality Details",
      subTitle: "Locality Details",
    },
    // {
    //   route: "/app/listing/add/pg-details",
    //   title: "PG Details",
    //   subTitle: "PG Details",
    // },
    // {
    //   route: "/app/listing/add/rental-details",
    //   title: "Rental Details",
    //   subTitle: "Rental Details",
    // },
    {
      route: "/app/listing/add/amenities",
      title: "Amenities",
      subTitle: "Amenities",
    },
    {
      route: "/app/listing/add/gallary",
      title: "Gallary",
      subTitle: "Gallary",
    },
  ];
  useEffect(() => {
    const fIdx =
      location.pathname === "/app/listing/add"
        ? 0
        : stepper.findIndex((step) => step.route === location.pathname);
    setActiveIndex(fIdx);
  }, [location]);
  return (
    <>
      <BsStepperStyle />
      <h4 className="fw-bold py-0 mb-4">
        <span className="text-muted fw-light">Home/</span> Add Package -{" "}
        <small style={{ fontWeight: 500 }}>{stepper[activeIndex].title}</small>
      </h4>
      <div id="add-package" className="bs-stepper vertical mt-2">
        <div className="bs-stepper-header">
          {stepper.map((step, idx) => (
            <div
              className={"step" + (activeIndex === idx ? " active" : "")}
              key={idx}
            >
              <Link type="button" className="step-trigger" to={step.route+ location.search}>
                <span className="bs-stepper-circle">{idx + 1}</span>
                <span className="bs-stepper-label">
                  <span className="bs-stepper-title">{step.title}</span>
                  <span className="bs-stepper-subtitle">{step.subTitle}</span>
                </span>
              </Link>
            </div>
          ))}
        </div>
        <div className="bs-stepper-content">
          <div className="content active dstepper-block">
            <Outlet stepper={stepper} />
          </div>
        </div>
      </div>
    </>
  );
}
