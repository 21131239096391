import React, { useEffect, useMemo, useState } from "react";
import { Col, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "../../components/table/Index";
import { getPropertydetailbybuyerid, getUserDetail } from "../../config/api";

function BuyerUserDetails() {
  const [modalShow, setModalShow] = React.useState(false);
  let location = useLocation();
  const navigate = useNavigate();
  // const [data, setData] = useState([
  //   {
  //     name: "vidya",
  //     email: "vidya@gmail.com",
  //     mobile: "76823792",
  //   },
  // ]);
  
  const [data, setData] = useState([]);
  const [propertydata, setPropertyData] = useState([]);
  useEffect(() => {
    //check_islogin();
    bindData();
  }, []);
  
  async function bindData() {
    await getUserDetail({Type:'buyer'})
      .then((response) => {
        if (response.length > 0) {
          setData(response);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }
  
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email ID",
        accessor: "email",
      },
      {
        Header: "Mobile Number",
        accessor: "mobile",
      },
      // {
      //   Header: "action",
      //   Cell: ({ row }) => {
      //     return (
      //       <div className="d-flex">
      //         <button
      //           className="border-0 bg-transparent text-primary d-flex align-items-center me-3"
      //           //onClick={() =>{ bindPropertData(row.original.id);setModalShow(true)}}
      //           onClick={()=>
      //             {
      //               navigate(`/app/uproperty-details?t=buyer&id=${row.original.id}`);
      //         }}
      //         >
      //           View More
      //         </button>
      //       </div>
      //     );
      //   },

      //   accessor: "action",
      // },
    ],
    []
  );
  return (
    <>
      <div>
        <h4 className="fw-bold py-3 mb-4">
          <span className="text-muted fw-light">Home/</span> Buyer User Details
        </h4>

        <div className="card">
          <div className="card-datatable table-responsive">
            <div className="dataTables_wrapper dt-bootstrap5 no-footer">
              {data ? <Table columns={columns} data={data} /> : <></>}
            </div>
            <PropBlog show={modalShow} onHide={() => setModalShow(false)} propertydata={propertydata?propertydata:[]} />
          </div>
        </div>
      </div>
    </>
  );
}

function PropBlog(props) {
  
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Request for Property
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Properties</h5>
          {props.propertydata.map((item,idx)=>(
          <a key={idx} ><p>{item.PropertyName}</p></a>
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BuyerUserDetails;
