import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

function SellerPropDetails() {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <div>
        <h4 className="fw-bold py-3 mb-4">
          <span className="text-muted fw-light">Home/</span>{" "}
          <span className="text-muted fw-light">Seller User Details/</span>{" "}
          Seller Property Details
        </h4>
        <Row>
          <Col lg={4} md={12} sm={12}>
            <div className="prop-bg-color">
              <div className="prop-card">
                <div className="flex">
                  <p className="mb-0">PROPERTY NAME :</p>
                  <p>Green Crest Taloja</p>
                </div>
                <div className="flex">
                  <p>PRICE :</p>
                  <p>₹ 86,00,000</p>
                </div>
                <div className="flex">
                  <p>SIZE :</p>
                  <p>5000 sq / ft</p>
                </div>
                <div className="flex">
                  <p>BHK TYPE :</p>
                  <p>3 BHK</p>
                </div>
                <div className="view-verify">
                  <p
                    className="cursor-pointer"
                    onClick={() => setModalShow(true)}
                  >
                    View More
                  </p>
                  <p href="/">Verify</p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={12} sm={12}>
            <div className="prop-bg-color">
              <div className="prop-card">
                <div className="flex">
                  <p>PROPERTY NAME :</p>
                  <p>Green Crest Taloja</p>
                </div>
                <div className="flex">
                  <p>PRICE :</p>
                  <p>₹ 86,00,000</p>
                </div>
                <div className="flex">
                  <p>SIZE :</p>
                  <p>5000 sq / ft</p>
                </div>
                <div className="flex">
                  <p>BHK TYPE :</p>
                  <p>3 BHK</p>
                </div>
                <div className="view-verify">
                  <p
                    className="cursor-pointer"
                    onClick={() => setModalShow(true)}
                  >
                    View More
                  </p>
                  <p>Verify</p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={12} sm={12}>
            <div className="prop-bg-color">
              <div className="prop-card">
                <div className="flex">
                  <p>PROPERTY NAME :</p>
                  <p>Green Crest Taloja</p>
                </div>
                <div className="flex">
                  <p>PRICE :</p>
                  <p>₹ 86,00,000</p>
                </div>
                <div className="flex">
                  <p>SIZE :</p>
                  <p>5000 sq / ft</p>
                </div>
                <div className="flex">
                  <p>BHK TYPE :</p>
                  <p>3 BHK</p>
                </div>
                <div className="view-verify">
                  <p
                    className="cursor-pointer"
                    onClick={() => setModalShow(true)}
                  >
                    View More
                  </p>
                  <p href="/" 
                  >Verify</p>
                </div>
              </div>
            </div>
          </Col>
          <PropBlog show={modalShow} onHide={() => setModalShow(false)} />
        </Row>
      </div>
    </>
  );
}

function PropBlog(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Property Details 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="seller-prop">
            <div className="flex">
              <p>PROPERTY NAME :</p>
              <p>Green Crest Taloja</p>
            </div>
            <div className="flex">
              <p>DEVELOPER NAME :</p>
              <p>Sai Shardha Developers & Rajdhyan Ventures</p>
            </div>
            <div className="flex">
              <p>PRICE :</p>
              <p>₹ 86,00,000</p>
            </div>
            <div className="flex">
              <p>SIZE :</p>
              <p>5000 sq / ft ft</p>
            </div>
            <div className="flex">
              <p>BHK TYPE :</p>
              <p>3 BHK</p>
            </div>
          </div>
          <h4>Other Featurs</h4>
          <Row>
            <Col lg={4} md={12} sm={12}>
              <p>Lawn</p>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <p>Gym</p>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <p>Swimming Pool</p>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <p>Meditation Zone</p>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <p>Outdoor & Indoor Spoarts Area</p>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <p>Spa & Steam room</p>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <p>Library & Classroom</p>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <p>Party Lawn & Hall</p>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <p>Lounge, Theater or Cafeteria</p>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SellerPropDetails;
