//import React from "react";
import FormControl from "../../../components/FormControl";
import { Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  iupgdetail,
  getPgdetailForDDL,
  getPgdetailDetail,
  getPgdetailDelete,
  getPgdetailDetailByID,
  useQuery,
} from "../../../config/api";
import { useNavigate, useLocation } from "react-router-dom";

function PGDetails({ setSmShow, editID }) {

  
  let location = useLocation();
  let navigate = useNavigate();
  let query = useQuery();

   //const propertydetailid = query.get("id");

 // const id = query.get("id");
 const id = query.get("id");
  const [state, setState] = useState({
    PGID: "0",
    PropertydetailID:id,
    Gender: "",
    PreferedGuest: "",
    AvailableDate: "",
    FoodInclude: "",
    Rule: "",
    GateClosingTime: "",
    Description: "",
    CreatedBy: sessionStorage.getItem("UserID"),
    errors: [],
  });


  //const id = query.get("id");
  //const id = editID;
  useEffect(() => {
    // BindEducationDDL();
    if (id !== "" && id !== null && id !== "0" && id !== 0 && typeof id !== "undefined") {
      UpdateData(id);
    }
  }, []);

  console.log(state);

  const preferedguestoptions = [
    { value: "YES", text: "YES" },
    { value: "NO", text: "NO" },

  ];

  function handlechange(e) {
    
    if (e.target.name === "Rule") {
      let temp = [];
      for(let i=0;i<e.target.parentElement.parentElement.children.length;i++){
      if (e.target.parentElement.parentElement.children[i].children[0].checked) {
        temp.push(e.target.parentElement.parentElement.children[i].children[0].value);
      }
    }
      setState({ ...state, [e.target.name]: temp.join(',') });
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  }
  console.log(state);
  async function SaveData() {
    
    let errors = {};
    //window.location.href = "/app/package/add/itinerary?id=9";
    if (validate()) {
      await iupgdetail(state)
        .then((response) => {
          if (response.ID !== "exists") {
            alert("PG Details Saved Successfully");
            ResetState();
           // window.location.reload(true);
            navigate(
              `/app/listing/add/rental-details${
                location.search.includes("m=e")
                  ? location.search
                  : "?id=" + id
              }`
            );
          }
          // else {
          //   errors["PropertyType"] = "This Property Type Already exists...";
          // }
          setState({
            ...state,
            errors: errors,
          });
        })
        .catch((error) => {
          alert(error);
        });
    }
  }


  async function UpdateData(PropertydetailID) {
    await getPgdetailDetailByID(PropertydetailID)
      .then((response) => {
        if (response !== "" && response?.length > 0) {
        setState(response[0]);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }


  async function ResetState() {
    setState({
      PGID: "0",
      PropertydetailID:id,
      Gender: "",
      PreferedGuest: "",
      AvailableDate: "",
      FoodInclude: "",
      Rule: "",
      GateClosingTime: "",
      Description: "",
      CreatedBy: sessionStorage.getItem("UserID"),
      errors: [],
    });
  }


  function validate() {
    let errors = {};
    let IsValid = true;

    if (!state?.Gender) {
      IsValid = false;
      errors["Gender"] = "Gender is Required";
    }
    if (!state?.PreferedGuest) {
      IsValid = false;
      errors["PreferedGuest"] = "Prefered Guest is Required";
    }
    if (!state?.AvailableDate) {
      IsValid = false;
      errors["AvailableDate"] = "Available Date is Required";
    }
    if (!state?.FoodInclude) {
      IsValid = false;
      errors["FoodInclude"] = "Food Include is Required";
    }
    if (!state?.Rule) {
      IsValid = false;
      errors["Rule"] = "Rule is Required";
    }
    if (!state?.GateClosingTime) {
      IsValid = false;
      errors["GateClosingTime"] = "Gate Closing Time is Required";
    }
    if (!state?.Description) {
      IsValid = false;
      errors["Description"] = "Description is Required";
    }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  }


  console.log(state);


  return (
    <>
      <div className="row g-3">
        <span>PLACE IS AVAILABLE FOR</span>
        <Form>
          {["radio"].map((type) => (
            <div key={`inline-${type}`} className="mb-3">
              <Form.Check
                inline
                label="Male"
                name="Gender"
                type={type}
                id={`inline-${type}-1`}
                value="Male"
                onChange={handlechange}
                defaultChecked={state.Gender === "Male" ? true : false}
              />
              <Form.Check
                inline
                label="Female"
                name="Gender"
                type={type}
                id={`inline-${type}-2`}
                value="Female"
                onChange={handlechange}
                defaultChecked={state.Gender === "Female" ? true : false}
              />
              <Form.Check
                inline
                label="Anyone"
                name="Gender"
                type={type}
                id={`inline-${type}-2`}
                value="Anyone"
                onChange={handlechange}
                defaultChecked={state.Gender === "Anyone" ? true : false}
              />
            </div>
          ))}
        </Form>
        <FormControl
          wrapperClass="col-sm-6"
          label="Preferred Guest"
          type="select"
          data-name="preferred guest"
          name="PreferedGuest"
          value={state.PreferedGuest}
          onChange={handlechange}
          options={preferedguestoptions}
          error={
            state.errors
              ? state.errors.PreferedGuest
                ? state.errors.PreferedGuest
                : ""
              : ""
          }
        />
        <FormControl
          wrapperClass="col-sm-6"
          label="Available Date"
          type="date"
          data-name="available date"
          name="AvailableDate"
          value={state.AvailableDate}
          onChange={handlechange}
          // options={parkingddl}
          error={
            state.errors
              ? state.errors.AvailableDate
                ? state.errors.AvailableDate
                : ""
              : ""
          }
        />
        <span>FOOD INCLUDED</span>
        <Form>
          {["radio"].map((type) => (
            <div key={`inline-${type}`} className="mb-3">
              <Form.Check
                inline
                label="Yes"
                // name="group1"
                name="FoodInclude"
                type={type}
                id={`inline-${type}-1`}
                value="Yes"
                onChange={handlechange}
                defaultChecked={state.FoodInclude === "Yes" ? true : false}
              />
              <Form.Check
                inline
                label="No"
                // name="group1"
                name="FoodInclude"
                type={type}
                id={`inline-${type}-2`}
                value="No"
                onChange={handlechange}
                defaultChecked={state.FoodInclude === "No" ? true : false}
              />
            </div>
          ))}
        </Form>
        <span>PG / HOSTEL RULE</span>
        <Form>
          {["checkbox"].map((type) => (
            <div key={`inline-${type}`} className="mb-3">
              <Form.Check
                inline
                label="No Smoking"
                name="Rule"
                type={type}
                id={`inline-${type}-1`}
                value="No Smoking"
                onChange={handlechange}
                checked={state.Rule.includes("No Smoking")}
              />
              <Form.Check
                inline
                label="No Guardians Stay"
                name="Rule"
                type={type}
                id={`inline-${type}-2`}
                value="No Guardians Stay"
                onChange={handlechange}
                checked={state.Rule.includes("No Guardians Stay")}
              />
              <Form.Check
                inline
                label="No Drinking"
                name="Rule"
                type={type}
                id={`inline-${type}-2`}
                value="No Drinking"
                onChange={handlechange}
                checked={state.Rule.includes("No Drinking")}
              />
            </div>
          ))}
        </Form>
        <FormControl
          wrapperClass="col-sm-6"
          label="Gate Closing Time"
          type="date"
          data-name="gate closing time"
          name="GateClosingTime"
          value={state.GateClosingTime}
          onChange={handlechange}
          //options={parkingddl}
          error={
            state.errors
              ? state.errors.GateClosingTime
                ? state.errors.GateClosingTime
                : ""
              : ""
          }
        />
        <FormControl
          wrapperClass="col-sm-12"
          label="Description"
          type="textbox"
          data-name="description"
          name="Description"
          value={state.Description}
          onChange={handlechange}
          // options={parkingddl}
          error={
            state.errors
              ? state.errors.Description
                ? state.errors.Description
                : ""
              : ""
          }
        />
        <div className="col-12">
          <div className="text-center">
            {/* <button onClick={ResetState} className="btn btn-warning me-3">reset</button>
            <button onClick={SaveData} className="btn btn-primary">submit</button> */}


<div className="col-12 d-flex justify-content-between mt-5">
        <button
          className="btn btn-label-secondary btn-prev d-flex align-items-center"
          onClick={() => {
            navigate(`/app/listing/add/locality-details${location.search}`);
          }}
        >
          <i className="bx bx-chevron-left bx-sm ms-sm-n2"></i>
          <span className="align-middle d-sm-inline-block d-none">
            Previous
          </span>
        </button>
        <button
          onClick={SaveData}
          className="btn btn-primary btn-next d-flex align-items-center"
        >
          <span className="align-middle d-sm-inline-block d-none me-sm-1">
            Next
          </span>
          <i className="bx bx-chevron-right bx-sm me-sm-n2"></i>
        </button>
      </div>



          </div>
        </div>
      </div>
    </>
  );
}

export default PGDetails;
