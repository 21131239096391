import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { dashboardcount } from "../config/api";

export default function Dashboard() {
  const [data, setData] = useState({ TotalProperty: 0, TotalSeller: 0, TotalBuyer: 0 });
  useEffect(() => {
    //check_islogin();
    bindData();
  }, []);
  async function bindData() {
    await dashboardcount()
      .then((response) => {
        if (response.length > 0) {
          setData({ TotalProperty: response[0].TotalProperty, TotalSeller: response[0].TotalSeller, TotalBuyer: response[0].TotalBuyer });
        }
      })
      .catch((error) => {
        alert(error);
      });
  }
  console.log(data);

  return (
    <>
      <h4 className="fw-bold py-3 mb-4">
        <span className="text-muted fw-light">Home/</span> Dashboard
      </h4>

      <Row className="mb-4">
        <Col lg={3} md={6} sm={6}>
          <div className="widget-section">
            <div className="pack-Total">
              <p className="count">{data.TotalProperty}</p>
              <p className="package">Total Property</p>
              {/* <p className="total">In India</p> */}
            </div>
            <div className="package-img">
              <img src="/images/4.png" alt="" />
            </div>
          </div>
        </Col>
        <Col lg={3} md={6} sm={6}>
          <div className="widget-section">
            <div className="pack-Total">
              <p className="count">{data.TotalBuyer}</p>
              <p className="package">Total Buyer</p>
              {/* <p className="total">In India</p> */}
            </div>
            <div className="package-img">
              <img src="/images/4.png" alt="" />
            </div>
          </div>
        </Col>{" "}
        <Col lg={3} md={6} sm={6}>
          <div className="widget-section">
            <div className="pack-Total">
              <p className="count">{data.TotalSeller}</p>
              <p className="package">Total Seller</p>
              {/* <p className="total">In India</p> */}
            </div>
            <div className="package-img">
              <img src="/images/4.png" alt="" />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
