import React, { useEffect, useState } from "react";
import FormControl from "../../../components/FormControl";
import { Col, Form } from "react-bootstrap";
import {
  getPropertydetailDetailByID,
  iupropertydetail,
  useQuery,
} from "../../../config/api";
import { useNavigate, useLocation } from "react-router-dom";

function NewPropertyDetails() {
  let location = useLocation();
  let navigate = useNavigate();
  let query = useQuery();
  const id = query.get("id");
  const [yesProject, setYesProject] = useState(true);
  const [NoProject, setNoProject] = useState(false);
  const [state, setState] = useState({
    PropertydetailID: "0",
    Name: "",
    PlanType: "",
    SubPlanType: "",
    PropertyType: "",
    MinPropertyPrice: "",
    MinPropertyPriceType: "",
    MaxPropertyPrice: "",
    MaxPropertyPriceType: "",
    RentalPrice: "",
    RentalPriceType: "",
    PropertyPriceInfo: "",
    PropertySize: "",
    PropertySizeType: "",
    Availability: "",
    Furnishing: "",
    YearBuilt: "",
    ConstructionStatus: "",
    BHKType: "",
    Specification: "",
    CompletionMonth: "",
    CompletionYear: "",
    BHK_1_MinBudget: "",
    BHK_1_MinBudgetType: "",
    BHK_1_MaxBudget: "",
    BHK_1_MaxBudgetType: "",
    BHK_1_PropertySize: "",
    BHK_1_PropertySizeType: "",
    BHK_2_MinBudget: "",
    BHK_2_MinBudgetType: "",
    BHK_2_MaxBudget: "",
    BHK_2_MaxBudgetType: "",
    BHK_2_PropertySize: "",
    BHK_2_PropertySizeType: "",
    BHK_3_MinBudget: "",
    BHK_3_MinBudgetType: "",
    BHK_3_MaxBudget: "",
    BHK_3_MaxBudgetType: "",
    BHK_3_PropertySize: "",
    BHK_3_PropertySizeType: "",
    BHK_4_MinBudget: "",
    BHK_4_MinBudgetType: "",
    BHK_4_MaxBudget: "",
    BHK_4_MaxBudgetType: "",
    BHK_4_PropertySize: "",
    BHK_4_PropertySizeType: "",
    IsProject: "1",
    CreatedBy: "0",
    errors: [],
  });

  useEffect(() => {
    // BindEducationDDL();
    if (
      id !== "" &&
      id !== null &&
      id !== "0" &&
      id !== 0 &&
      typeof id !== "undefined"
    ) {
      UpdateData(id);
    }
  }, []);
  const handleChange = (e) => {
    debugger;
    if (e.target.name === "IsProject") {
      if (e.target.checked && e.target.value === "1") {
        funYesProject();
        setState({ ...state, [e.target.name]: "1" });
      } else {
        funNoProject();
        setState({ ...state, [e.target.name]: "0" });
      }
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };
  console.log(state);
  async function UpdateData(PropertydetailID) {
    debugger;
    await getPropertydetailDetailByID(PropertydetailID)
      .then((response) => {
        setState(response[0]);
        console.log(response);
      })
      .catch((error) => {
        alert(error);
      });
  }
  async function SaveData() {
    debugger;
    let errors = {};
    //window.location.href = "/app/package/add/itinerary?id=9";
    if (validate()) {
      await iupropertydetail(state)
        .then((response) => {
          if (response[0].ID !== "exists") {
            alert("Property Details Saved Successfully");
            ResetState();
            // window.location.reload(true);
            navigate(
              `/app/listing/add/locality-details${
                location.search.includes("m=e")
                  ? location.search
                  : "?id=" + response[0].ID
              }`
            );
          } else {
            //     errors["PropertyName"] = "This Property Name Already exists...";
            //   }
            //   setState({
            //     ...state,
            //     errors: errors,
            //   });
            // })
            //alert("This Property Name Already exists....");}
            let errors = {};
            errors["PropertyName"] = "This Property Name Already exists....";
            // }
            setState({
              ...state,
              errors: errors,
            });
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }
  function validate() {
    let errors = {};
    let IsValid = true;

    if (!state?.Name) {
      IsValid = false;
      errors["Name"] = "Name is Required";
    }

    // if (!state?.YearBuilt) {
    //   IsValid = false;
    //   errors["YearBuilt"] = "Year Built is Required";
    // }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  }
  async function ResetState() {
    setState({
      PropertydetailID: "0",
      Name: "",
      PlanType: "",
      SubPlanType: "",
      PropertyType: "",
      MinPropertyPrice: "",
      MinPropertyPriceType: "",
      MaxPropertyPrice: "",
      MaxPropertyPriceType: "",
      RentalPrice: "",
      RentalPriceType: "",
      PropertyPriceInfo: "",
      PropertySize: "",
      PropertySizeType: "",
      Availability: "",
      Furnishing: "",
      YearBuilt: "",
      ConstructionStatus: "",
      BHKType: "",
      Specification: "",
      CompletionMonth: "",
      CompletionYear: "",
      BHK_1_MinBudget: "",
      BHK_1_MinBudgetType: "",
      BHK_1_MaxBudget: "",
      BHK_1_MaxBudgetType: "",
      BHK_1_PropertySize: "",
      BHK_1_PropertySizeType: "",
      BHK_2_MinBudget: "",
      BHK_2_MinBudgetType: "",
      BHK_2_MaxBudget: "",
      BHK_2_MaxBudgetType: "",
      BHK_2_PropertySize: "",
      BHK_2_PropertySizeType: "",
      BHK_3_MinBudget: "",
      BHK_3_MinBudgetType: "",
      BHK_3_MaxBudget: "",
      BHK_3_MaxBudgetType: "",
      BHK_3_PropertySize: "",
      BHK_3_PropertySizeType: "",
      BHK_4_MinBudget: "",
      BHK_4_MinBudgetType: "",
      BHK_4_MaxBudget: "",
      BHK_4_MaxBudgetType: "",
      BHK_4_PropertySize: "",
      BHK_4_PropertySizeType: "",
      IsProject: "",
      CreatedBy: "0",
      errors: [],
    });
  }

  const funYesProject = () => {
    setYesProject(true);
    setNoProject(false);
  };

  const funNoProject = () => {
    setNoProject(true);
    setYesProject(false);
  };

  const [isActiveBuy, setIsActiveBuy] = useState(true);
  const [isActiveSellRent, setIsActiveSellRent] = useState(false);
  const [isActiveComm, setIsActiveComm] = useState(false);

  const [BuyShow, setBuyShow] = useState(true);
  const [RentShow, setRentShow] = useState(false);
  const [CommShow, setCommShow] = useState(false);

  const [CommBuyShow, setCommBuyShow] = useState(true);
  const [CommRentShow, setCommRentShow] = useState(false);
  const [CommSellShow, setCommSellShow] = useState(false);

  const funBuy = () => {
    setBuyShow(true);
    setRentShow(false);
    setCommShow(false);
    setCommBuyShow(false);
    setCommSellShow(false);
    setCommRentShow(false);

    setIsActiveBuy(true);
    setIsActiveSellRent(false);
    setIsActiveComm(false);
    setState({ ...state, PlanType: "Buy" });
  };
  const funRent = () => {
    setRentShow(true);
    setBuyShow(false);
    setCommShow(false);
    setCommBuyShow(false);
    setCommSellShow(false);
    setCommRentShow(false);

    setIsActiveSellRent(true);
    setIsActiveBuy(false);
    setIsActiveComm(false);
    setState({ ...state, PlanType: "SellOrRent" });
  };
  const funComm = () => {
    setCommShow(true);
    setBuyShow(false);
    setRentShow(false);
    setCommBuyShow(true);
    setCommSellShow(false);
    setCommRentShow(false);

    setIsActiveComm(true);
    setIsActiveBuy(false);
    setIsActiveSellRent(false);
    setState({ ...state, PlanType: "Commercial" });
  };

  const funCommBuyShow = () => {
    setCommBuyShow(true);
    setCommSellShow(false);
    setCommRentShow(false);
    setState({ ...state, SubPlanType: "Buy" });
  };
  const funCommRentShow = () => {
    setCommRentShow(true);
    setCommSellShow(false);
    setCommBuyShow(false);
    setState({ ...state, SubPlanType: "Rent" });
  };
  const funCommSellShow = () => {
    setCommSellShow(true);
    setCommRentShow(false);
    setCommBuyShow(false);
    setState({ ...state, SubPlanType: "Sell" });
  };

  const propertytypeoptions = [
    { value: "Apartment", text: "Apartment" },
    { value: "Independent House", text: "Independent House" },
    { value: "Plot", text: "Plot" },
    { value: "Studio", text: "Studio" },
    { value: "Villa", text: "Villa" },
    { value: "Agricultural Land", text: "Agricultural Land" },
    { value: "Other", text: "Other" },
  ];

  const propertytypeoptionscommercial = [
    { value: "Office Space", text: "Office Space" },
    { value: "Shop", text: "Shop" },
    { value: "Commercial Plot", text: "Commercial Plot" },
    { value: "Warehouse / Godown", text: "Warehouse / Godown" },
    { value: "Other", text: "Other" },
  ];

  const SqFtSqtr = [
    { value: "Sq. Ft", text: "Sq. Ft" },
    { value: "Sq. Mtr", text: "Sq. Mtr" },
  ];

  const availability = [
    { value: "Within 15 days", text: "Within 15 days" },
    { value: "Within 1 month", text: "Within 1 month" },
    { value: "After a month", text: "After a month" },
  ];

  const yearBuild = [
    {
      value: "2000-2005",
      text: "2000-2005",
    },
    {
      value: "2006-2010",
      text: "2006-2010",
    },
    {
      value: "2011- 2015",
      text: "2011- 2015",
    },
    {
      value: "2016-2020",
      text: "2016-2020",
    },
    {
      value: "2021-2023",
      text: "2021-2023",
    },
  ];

  const constructionStatus = [
    {
      value: "Ready to Move",
      text: "Ready to Move",
    },
    {
      value: "Under Construction",
      text: "Under Construction",
    },
  ];

  const Month = [
    {
      value: "January",
      text: "January",
    },
    {
      value: "February",
      text: "February",
    },
    {
      value: "March",
      text: "March",
    },
    {
      value: "April",
      text: "April",
    },
    {
      value: "May",
      text: "May",
    },
    {
      value: "June",
      text: "June",
    },
    {
      value: "July",
      text: "July",
    },
    {
      value: "August",
      text: "August",
    },
    {
      value: "Semptember",
      text: "Semptember",
    },
    {
      value: "October",
      text: "October",
    },
    {
      value: "November",
      text: "November",
    },
    {
      value: "December",
      text: "December",
    },
  ];

  const year = [
    {
      value: "2023",
      text: "2023",
    },
    {
      value: "2024",
      text: "2024",
    },
    {
      value: "2025",
      text: "2025",
    },
    {
      value: "2026",
      text: "2026",
    },
    {
      value: "2027",
      text: "2027",
    },
    {
      value: "2028",
      text: "2028",
    },
    {
      value: "2029",
      text: "2029",
    },
    {
      value: "2030",
      text: "2030",
    },
  ];

  const Budget = [
    {
      value: "Lacs",
      text: "Lacs",
    },
    {
      value: "Crore",
      text: "Crore",
    },
  ];
  const furnishing = [
    {
      value: "Fully Furnished",
      text: "Fully Furnished",
    },
    {
      value: "Semi Furnished",
      text: "Semi Furnished",
    },
    {
      value: "Unfurnished",
      text: "Unfurnished",
    },
  ];
  return (
    <>
      <div className="row g-3">
        <div className="row g-3">
          <label>Is Project</label>
          <div className="is-project">
            <div className="radio">
              <Form.Check
                inline
                label="Yes"
                name="IsProject"
                type="radio"
                value="1"
                //onClick={funYesProject}
                checked={state.IsProject === "1" ? true : false}
                onChange={handleChange}
                //defaultChecked
                readOnly
              />
              <Form.Check
                inline
                label="No"
                name="IsProject"
                type="radio"
                value="0"
                checked={state.IsProject === "0" ? true : false}
                //onClick={funNoProject}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="row my-2">
          <Col lg={6} md={12} sm={12}>
            <FormControl
              wrapperClass=""
              label={yesProject ? "Project Name" : "Property Name"}
              type="text"
              name="Name"
              value={state.Name}
              onChange={handleChange}
            />
            {state.errors ? (
              <div className="text-red">{state.errors.PropertyName}</div>
            ) : (
              ""
            )}
          </Col>
        </div>
        {yesProject ? (
          <div>
            <div className="row my-2">
              <p className="form-label">Completion Year</p>
              <Col lg={6} md={12} sm={12}>
                <FormControl
                  wrapperClass=""
                  label="Month"
                  type="select"
                  data-name="month"
                  name="CompletionMonth"
                  options={Month}
                  value={state.CompletionMonth}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <FormControl
                  wrapperClass=""
                  label="Year"
                  type="select"
                  data-name="year"
                  name="CompletionYear"
                  options={year}
                  value={state.CompletionYear}
                  onChange={handleChange}
                />
              </Col>
            </div>
            <h5 className="my-3 bhk">BHK Types</h5>
            <div className="row my-3">
              <p className="bhk">1 BHK</p>
              <Col lg={4} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Min Budget"
                  type="text"
                  data-name="minBudgetInput"
                  name="BHK_1_MinBudget"
                  value={state.BHK_1_MinBudget}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={2} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Lacs / Crore"
                  type="select"
                  data-name="minBudget"
                  name="BHK_1_MinBudgetType"
                  options={Budget}
                  value={state.BHK_1_MinBudgetType}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Max Budget"
                  type="text"
                  data-name="maxBudget"
                  name="BHK_1_MaxBudget"
                  value={state.BHK_1_MaxBudget}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={2} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Lacs / Crore"
                  type="select"
                  data-name="maxBudget"
                  name="BHK_1_MaxBudgetType"
                  options={Budget}
                  value={state.BHK_1_MaxBudgetType}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Property Size"
                  type="text"
                  data-name="PropertyOfSize"
                  name="BHK_1_PropertySize"
                  value={state.BHK_1_PropertySize}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={2} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Sq. Ft/ Sq. Mtr"
                  type="select"
                  data-name="SqFt/Sqtr"
                  name="BHK_1_PropertySizeType"
                  options={SqFtSqtr}
                  value={state.BHK_1_PropertySizeType}
                  onChange={handleChange}
                />
              </Col>
            </div>
            <div className="row my-3">
              <p className="bhk">2 BHK</p>
              <Col lg={4} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Min Budget"
                  type="text"
                  data-name="minBudgetInput"
                  name="BHK_2_MinBudget"
                  value={state.BHK_2_MinBudget}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={2} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Lacs / Crore"
                  type="select"
                  data-name="minBudget"
                  name="BHK_2_MinBudgetType"
                  options={Budget}
                  value={state.BHK_2_MinBudgetType}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Max Budget"
                  type="text"
                  data-name="maxBudget"
                  name="BHK_2_MaxBudget"
                  value={state.BHK_2_MaxBudget}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={2} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Lacs / Crore"
                  type="select"
                  data-name="maxBudget"
                  name="BHK_2_MaxBudgetType"
                  options={Budget}
                  value={state.BHK_2_MaxBudgetType}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Property Size"
                  type="text"
                  data-name="PropertyOfSize"
                  name="BHK_2_PropertySize"
                  value={state.BHK_2_PropertySize}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={2} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Sq. Ft/ Sq. Mtr"
                  type="select"
                  data-name="SqFt/Sqtr"
                  name="BHK_2_PropertySizeType"
                  options={SqFtSqtr}
                  value={state.BHK_2_PropertySizeType}
                  onChange={handleChange}
                />
              </Col>
            </div>
            <div className="row my-3">
              <p className="bhk">3 BHK</p>
              <Col lg={4} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Min Budget"
                  type="text"
                  data-name="minBudgetInput"
                  name="BHK_3_MinBudget"
                  value={state.BHK_3_MinBudget}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={2} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Lacs / Crore"
                  type="select"
                  data-name="minBudget"
                  name="BHK_3_MinBudgetType"
                  options={Budget}
                  value={state.BHK_3_MinBudgetType}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Max Budget"
                  type="text"
                  data-name="maxBudget"
                  name="BHK_3_MaxBudget"
                  value={state.BHK_3_MaxBudget}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={2} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Lacs / Crore"
                  type="select"
                  data-name="maxBudget"
                  name="BHK_3_MaxBudgetType"
                  options={Budget}
                  value={state.BHK_3_MaxBudgetType}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Property Size"
                  type="text"
                  data-name="BHK_3_PropertySize"
                  name="BHK_3_PropertySize"
                  value={state.BHK_3_PropertySize}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={2} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Sq. Ft/ Sq. Mtr"
                  type="select"
                  data-name="SqFt/Sqtr"
                  name="BHK_3_PropertySizeType"
                  options={SqFtSqtr}
                  value={state.BHK_3_PropertySizeType}
                  onChange={handleChange}
                />
              </Col>
            </div>
            <div className="row my-3">
              <p className="bhk">4 BHK</p>
              <Col lg={4} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Min Budget"
                  type="text"
                  data-name="minBudgetInput"
                  name="BHK_4_MinBudget"
                  value={state.BHK_4_MinBudget}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={2} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Lacs / Crore"
                  type="select"
                  data-name="minBudget"
                  name="BHK_4_MinBudgetType"
                  options={Budget}
                  value={state.BHK_4_MinBudgetType}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Max Budget"
                  type="text"
                  data-name="maxBudget"
                  name="BHK_4_MaxBudget"
                  value={state.BHK_4_MaxBudget}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={2} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Lacs / Crore"
                  type="select"
                  data-name="maxBudget"
                  name="BHK_4_MaxBudgetType"
                  options={Budget}
                  value={state.BHK_4_MaxBudgetType}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Property Size"
                  type="text"
                  data-name="PropertyOfSize"
                  name="BHK_4_PropertySize"
                  value={state.BHK_4_PropertySize}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={2} md={12} sm={12} className="my-1">
                <FormControl
                  wrapperClass=""
                  label="Sq. Ft/ Sq. Mtr"
                  type="select"
                  data-name="SqFt/Sqtr"
                  name="BHK_4_PropertySizeType"
                  options={SqFtSqtr}
                  value={state.BHK_4_PropertySizeType}
                  onChange={handleChange}
                />
              </Col>
            </div>
          </div>
        ) : (
          ""
        )}
        {NoProject ? (
          <div className="">
            <div className="buy-rent-comm">
              <p
                className={isActiveBuy ? "buy-active" : "buy"}
                onClick={funBuy}
              >
                Buy
              </p>
              <p
                className={isActiveSellRent ? "rent-active" : "rent"}
                onClick={funRent}
              >
                Sell / Rent
              </p>
              <p
                className={isActiveComm ? "comm-active" : "comm"}
                onClick={funComm}
              >
                Commercial
              </p>
            </div>
            <div className="property-form-wrapper">
              {/* Property type */}
              {BuyShow || RentShow || CommShow ? (
                <FormControl
                  wrapperClass=""
                  label="Property Type"
                  type="select"
                  data-name="PropertyType"
                  name="PropertyType"
                  placeholder="Ex."
                  options={
                    CommShow
                      ? propertytypeoptionscommercial
                      : propertytypeoptions
                  }
                  value={state.PropertyType}
                  onChange={handleChange}
                />
              ) : (
                ""
              )}

              {CommShow ? (
                <div>
                  <div className="row">
                    <div className="is-project my-3">
                      <Form.Check
                        inline
                        label="Buy"
                        name="commercial"
                        type="radio"
                        value="Buy"
                        onClick={funCommBuyShow}
                        defaultChecked
                      />
                      <Form.Check
                        inline
                        label="Sell"
                        name="commercial"
                        type="radio"
                        value="Sell"
                        onClick={funCommSellShow}
                      />
                      <Form.Check
                        inline
                        label="Rent"
                        name="commercial"
                        type="radio"
                        value="Rent"
                        onClick={funCommRentShow}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div>
                <div className="row my-2">
                  <p className="bhk">Property Price</p>
                  {/* {BuyShow || RentShow || CommBuyShow || CommRentShow || CommSellShow ? ( */}
                  {BuyShow || CommBuyShow ? (
                    <div className="row my-2">
                      <Col lg={4} md={12} sm={12}>
                        <FormControl
                          wrapperClass=""
                          label="Min Budget"
                          type="text"
                          data-name="minBudgetInput"
                          name="MinPropertyPrice"
                          value={state.MinPropertyPrice}
                          onChange={handleChange}
                          // options={Budget}
                        />
                      </Col>
                      <Col lg={2} md={12} sm={12}>
                        <FormControl
                          wrapperClass=""
                          label="Lacs / Crore"
                          type="select"
                          data-name="minBudget"
                          name="MinPropertyPriceType"
                          options={Budget}
                          value={state.MinPropertyPriceType}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col lg={4} md={12} sm={12}>
                        <FormControl
                          wrapperClass=""
                          label="Max Budget"
                          type="text"
                          data-name="maxBudgetInput"
                          name="MaxPropertyPrice"
                          value={state.MaxPropertyPrice}
                          onChange={handleChange}
                          // options={Budget}
                        />
                      </Col>
                      <Col lg={2} md={12} sm={12}>
                        <FormControl
                          wrapperClass=""
                          label="Lacs / Crore"
                          type="select"
                          data-name="maxBudget"
                          name="MaxPropertyPriceType"
                          options={Budget}
                          value={state.MaxPropertyPriceType}
                          onChange={handleChange}
                        />
                      </Col>
                    </div>
                  ) : (
                    ""
                  )}
                  {RentShow || CommRentShow || CommSellShow ? (
                    <div className="row my-2">
                      <Col lg={4} md={12} sm={12}>
                        <FormControl
                          wrapperClass=""
                          label="Property Price"
                          type="text"
                          data-name="PropertyPrice"
                          name="MinPropertyPrice"
                          value={state.MinPropertyPrice}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col lg={2} md={12} sm={12}>
                        <FormControl
                          wrapperClass=""
                          label="Lacs / Crore"
                          type="select"
                          data-name="maxBudget"
                          name="MinPropertyPriceType"
                          options={Budget}
                          value={state.MinPropertyPriceType}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col lg={4} md={12} sm={12}>
                        <FormControl
                          wrapperClass=""
                          label="Rental Price"
                          type="text"
                          data-name="RentalPrice"
                          name="RentalPrice"
                          value={state.RentalPrice}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col lg={2} md={12} sm={12}>
                        <FormControl
                          wrapperClass=""
                          label="Lacs / Crore"
                          type="select"
                          data-name="maxBudget"
                          name="RentalPriceType"
                          options={Budget}
                          value={state.RentalPriceType}
                          onChange={handleChange}
                        />
                      </Col>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row my-2">
                  <Col lg={4} md={12} sm={12}>
                    <FormControl
                      wrapperClass=""
                      label="Property Size"
                      type="text"
                      data-name="PropertyOfSize"
                      name="PropertySize"
                      value={state.PropertySize}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col lg={2} md={12} sm={12}>
                    <FormControl
                      wrapperClass=""
                      label="Sq. Ft/ Sq. Mtr"
                      type="select"
                      data-name="SqFt/Sqtr"
                      name="PropertySizeType"
                      options={SqFtSqtr}
                      value={state.PropertySizeType}
                      onChange={handleChange}
                    />
                  </Col>
                  {/* {BuyShow ? (
                    <Col sm={12} lg={6} md={12}>
                      <FormControl
                        wrapperClass=""
                        label="No. Of Bedrooms"
                        type="text"
                        data-name="NoOfBedrooms"
                        name="NoOfBedrooms"
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                  {RentShow ? (
                    <Col sm={12} lg={6} md={12}>
                      <FormControl
                        wrapperClass=""
                        label="No. Of Bedrooms"
                        type="text"
                        data-name="NoOfBedrooms"
                        name="NoOfBedrooms"
                      />
                    </Col>
                  ) : (
                    ""
                  )} */}

                  <Col sm={12} lg={6} md={12}>
                    <FormControl
                      wrapperClass=""
                      label="Availability"
                      type="select"
                      data-name="Availability"
                      name="Availability"
                      placeholder="Ex."
                      options={availability}
                      value={state.Availability}
                      onChange={handleChange}
                    />
                  </Col>
                </div>
                <div className="row my-2">
                  <Col sm={12} lg={6} md={12}>
                    <FormControl
                      wrapperClass=""
                      label="Furnishing"
                      type="select"
                      data-name="furnishing"
                      name="Furnishing"
                      options={furnishing}
                      value={state.Furnishing}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col lg={6} md={12} sm={12}>
                    <FormControl
                      wrapperClass=""
                      label="Year Build"
                      type="select"
                      data-name="YearBuild"
                      name="YearBuilt"
                      options={yearBuild}
                      value={state.YearBuilt}
                      onChange={handleChange}
                    />
                    {state.errors ? (
                      <div className="text-red">{state.errors.YearBuilt}</div>
                    ) : (
                      ""
                    )}
                  </Col>

                  {/* <Col lg={6} md={12} sm={12}>
                    <FormControl
                      wrapperClass=""
                      label="Locality"
                      type="text"
                      data-name="Locality"
                      name="Locality"
                    />
                  </Col> */}
                </div>
                <div className="row my-2">
                  {BuyShow || CommBuyShow || CommRentShow || CommSellShow ? (
                    <Col lg={6} md={12} sm={12}>
                      <FormControl
                        wrapperClass=""
                        label="Construction Status"
                        type="select"
                        data-name="ConstructionStatus"
                        name="ConstructionStatus"
                        options={constructionStatus}
                        value={state.ConstructionStatus}
                        onChange={handleChange}
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                  <div className="row my-2">
                    <Col lg={12} md={12} sm={12}>
                      {/* <FormControl
                      wrapperClass=""
                      label="Other Specification"
                      type="text"
                      data-name="OtherSpecification"
                      name="OtherSpecification"
                    /> */}
                      <p className="form-label">Other Specification</p>
                      <textarea
                        className="form-control"
                        rows="3"
                        placeholder="Enter description here..."
                        name="Specification"
                        value={state.Specification}
                        onChange={handleChange}
                      ></textarea>
                    </Col>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="col-12 d-flex justify-content-end mt-5">
          <button
            onClick={SaveData}
            className="btn btn-primary btn-next d-flex align-items-center"
          >
            <span className="align-middle d-sm-inline-block d-none me-sm-1">
              Next
            </span>
            <i className="bx bx-chevron-right bx-sm me-sm-n2"></i>
          </button>
        </div>
      </div>
    </>
  );
}

export default NewPropertyDetails;
