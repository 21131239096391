//import React from "react";
import FormControl from "../../../components/FormControl";
import React, { useEffect, useState } from "react";
import {
  iugallary,
  getGallaryForDDL,
  getGallaryDetail,
  getGallaryDelete,
  getGallaryDetailByID,
  useQuery,
  uploadPackageGallery,
  getPropertyImageCountByPropertyID,
  deleteImage,
  getPackageGallery,
} from "../../../config/api";
import { useNavigate, useLocation } from "react-router-dom";
import { baseURL, check_islogin } from "../../../config/constant";
//import { baseURL, check_islogin, getFileName } from "../../../config/constant";


function Gallary({ setSmShow, editID }) {

  let location = useLocation();
  let navigate = useNavigate();
  let query = useQuery();

  //const propertydetailid = query.get("id");

  // const id = query.get("id");
  const propertyid = query.get("id");
  const id = query.get("id");
  const [mode, setMode] = useState("add");
  const [state, setState] = useState({
    // GallaryID: "0",
    // PropertydetailID:id,
    PropertydetailID: "0",
    PropertyImage: "",
    CreatedBy: sessionStorage.getItem("UserID"),
    errors: [],
  });
  const [uploadfile, setUploadFile] = useState({ File: "", Ext: "" });
  //const id = query.get("id");
  // const id = editID;
  useEffect(() => {
    // BindEducationDDL();
    if (id !== "" && id !== null && id !== "0" && id !== 0 && typeof id !== "undefined") {
      // UpdateData(id);
    }
  }, []);



  const [data, setData] = useState([]);
  console.log(data);
  useEffect(() => {
    
    if (query.get("id")) {
      getData();
    }
  }, []);
  async function getData() {
    
    await getGallaryDetail(propertyid)
      .then((response) => {
        if (response) {
          
          setData(response[0]);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }
  console.log(data);



  // function handlechange(e) {
  //   setState({ ...state, [e.target.name]: e.target.value });
  // }



  async function _deleteImage(imageid) {
    if (window.confirm("Are you sure to delete.?")) {
      await deleteImage(imageid)
        .then((response) => {
          if (response) {
            //getData();
            alert("Deleted Successfully");
            window.location.reload(true);
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }
  function _updateImage(imageid) { }

  const [progressimage, setProgressimage] = useState();
  let percentimage = 0;
  const configimage = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      percentimage = Math.round((100 * loaded) / total); //Math.floor((loaded * 100) / total)

      console.log(`${loaded}kb of ${total}kb | ${percentimage}%`); // just to see whats happening in the console
      if (percentimage <= 100) {
        setProgressimage(percentimage); // hook to set the value of current level that needs to be passed to the progressbar
      }
    },
    headers: {
      // custom headers goes here
    },
  };
  async function UploadImage() {
    let imagecount = 0; 
    await getPropertyImageCountByPropertyID(propertyid).then((response) => {
      imagecount = response[0][0].imagecount;
    });
    const formData = new FormData();
    formData.append(
      "file",
      uploadfile.File,
      "image_" + imagecount + "." + uploadfile.Ext
    );
    formData.append("PropertydetailID", propertyid);
   // if (validate()) {
    await iugallary({
      //ID: 0,
      GallaryID: 0,
      PropertydetailID: propertyid,
      PropertyImage:
        "admin/uploads/gallery/" +
        propertyid +
        "/image_" +
        imagecount +
        "." +
        uploadfile.Ext,
      CreatedBy: sessionStorage.getItem("UserID"),
    }).then((response) => {
      uploadPackageGallery(formData)
        .then((response) => {
          // alert(response.success);
          if (response.status === "success") {
            alert("Image Uploaded Successfully");
            window.location.reload(true);
            getData();
     
            // setTimeout(() => {
            //   //alert("File Uploaded Successfully");
            //   alert("Image Uploaded Successfully");
            //   ResetState();
            // }, 600);
          }
          
        })
        .catch((error) => {
          alert(error);
        });
    });
  //}
}
  async function handlechangeimage(e) {
    setUploadFile({
      File: e.target.files[0], Ext: e.target.files[0].name.split(".").pop()
    })

  }




  async function SaveData() {
    let errors = {};
    //window.location.href = "/app/package/add/itinerary?id=9";
    if (validate()) {
      await iugallary(state)
        .then((response) => {
          
          if (response.ID !== "exists") {
            alert("Property details Save Successfully");
            ResetState();
            //navigate("/app/manage-property");

            //  window.location.reload(true);
            navigate(
              `/app/manage-property${location.search.includes("m=e")
                ? location.search
                : "?id=" + id
              }`
            );
          }
          //  else {
          //   errors["PropertyType"] = "This Property Type Already exists...";
          // }
          setState({
            ...state,
            errors: errors,
          });
        })
        // .catch((error) => {
        //   alert(error);
        // });
        .catch((err) => {
          console.error(err);
        });
    }
  }

  // async function UpdateData(PropertydetailID) {
  //   await getGallaryDetailByID(PropertydetailID)
  //     .then((response) => {
  //       if (response !== "" && response?.length > 0) {
  //       setState(response[0]);
  //       }
  //     })
  //     .catch((error) => {
  //       alert(error);
  //     });
  // }



  async function ResetState() {
    setState({
      GallaryID: "0",
      PropertydetailID: id,
      PropertyImage: "",
      CreatedBy: sessionStorage.getItem("UserID"),
      errors: [],
    });
  }


  function validate() {
    let errors = {};
    let IsValid = true;


    if (!state?.PropertyImage) {
      IsValid = false;
      errors["PropertyImage"] = "Property Image is Required";
    }


    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  }

  console.log(state);


  return (
    <>

      <div className="row g-3">
        <FormControl
          wrapperClass="col-sm-6"
          label="Image"
          type="file"
          data-name="Image"
          id="Image"
          name="Image"
          disabled={mode === "view" ? true : false}
          onChange={handlechangeimage}
          accept=".jpg ,.jpeg"
          className="col-sm-6"
        />
        {state.errors ? (
                  <div className="text-red">
                    {state.errors.Image}
                  </div>
                ) : (
                  ""
                )}
        <span>{state.Image}</span>
        <div className="col-12">
          
          {/* <button onClick={ResetState} className="btn btn-warning me-3">reset</button> */}
          <button onClick={UploadImage} className="btn btn-primary">Upload</button>
        </div>


        <div className="col-12">
          <div className="text-center">

            <div className="d-flex p-3 gap-3 flex-wrap">
              {data?.map((item, idx) => (
                <div
                  className="d-flex justify-content-center flex-wrap border rounded w-24 p-2"
                  key={idx}
                >
                  <a href={baseURL + "/" + item.PropertyImage} target="_blank">
                    <img
                      src={baseURL + "/" + item.PropertyImage}
                      width="100"
                      alt=""
                      className="d-block"
                    />
                  </a>
                  <div className="w-100 text-center pt-1 justify-content-center pb-2">
                    {/* <span>{item.PropertyImage}</span> */}
                  </div>
                  <button
                    onClick={() => _deleteImage(item.ImageID)}
                    className="mx-auto bg-white border rounded text-primary"
                  >
                    delete
                  </button>
                </div>
              ))}
            </div>


            {state.errors ? (
                  <div className="text-red">
                    {state.errors.PropertyImage}
                  </div>
                ) : (
                  ""
                )}




            <div className="col-12 d-flex justify-content-between mt-5">
              <button
                className="btn btn-label-secondary btn-prev d-flex align-items-center"
                // onClick={() => {
                //   navigate(`/app/listing/add/amenities${location.search}`);
                // }}
                onClick={() => {
                  window.location.href =
                    "/app/listing/add/amenities?id=" + propertyid;
                }}
              >
                <i className="bx bx-chevron-left bx-sm ms-sm-n2"></i>
                <span className="align-middle d-sm-inline-block d-none">
                  Previous
                </span>
              </button>
              <button
               // onClick={SaveData}
               onClick={() => {
                window.location.href =
                  "/app/manage-property" ;
              }}
                //  onClick={() => {
                //   window.location.href =
                //     "/app/listing/add/other-details?id=" + propertyid;
                // }}
                className="btn btn-primary btn-next d-flex align-items-center"
              >
                <span className="align-middle d-sm-inline-block d-none me-sm-1">
                  Save
                </span>
                <i className="bx bx-chevron-right bx-sm me-sm-n2"></i>
              </button>
            </div>


          </div>
        </div>
      </div>

    </>
  );
}

export default Gallary;
