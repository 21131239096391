import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import AppLayout from "./layout/AppLayout";
import PackageAddLayout from "./layout/PackageAddLayout";

import Dashboard from "./pages/Dashboard";
import PropertyDetails from "./pages/package/add/PropertyDetails";
import RentalDetails from "./pages/package/add/RentalDetails";
import LocalityDetails from "./pages/package/add/LocalityDetails";
import Amenities from "./pages/package/add/Amenities";
import Gallary from "./pages/package/add/Gallary";
import PGDetails from "./pages/package/add/PGDetails";
//import ManageProperty from "./pages/package/ManagePropertyold";

import ManageProperty from "./pages/package/add/ManageProperty";
import Login from "./pages/Login";
import ForgorPassword from "./pages/ForgotPassword";
import SellerUserDetails from "./pages/Applications/SellerUserDetails";
import BuyerUserDetails from "./pages/Applications/BuyerUserDetails";
import SellerPropDetails from "./pages/Applications/SellerPropDetails";
import PropDetails from "./pages/Applications/PropertyDetail";
import VerifiedPropDetails from "./pages/Applications/verifiedpropertydetail";
import NewPropertyDetails from "./pages/package/add/NewPropertyDetails";
// add
export default function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />

        <Route path="/auth/forgot-password" element={<ForgorPassword />} />

        <Route path="/app" element={<AppLayout />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="seller-user-details" element={<SellerUserDetails />} />
          <Route path="buyer-user-details" element={<BuyerUserDetails />} />
          <Route
            path="seller-property-details"
            element={<SellerPropDetails />}
          />
          <Route
            path="uproperty-details"
            element={<PropDetails />}
          />
          <Route
            path="verified-uproperty-details"
            element={<VerifiedPropDetails />}
          />
          <Route path="manage-property" element={<ManageProperty />} />

          <Route path="listing/add" element={<PackageAddLayout />}>
            <Route index element={<PropertyDetails />} />
            {/* <Route path="property-details" element={<PropertyDetails />} /> */}
            <Route path="property-details" element={<NewPropertyDetails />} />
            <Route path="rental-details" element={<RentalDetails />} />
            <Route path="pg-details" element={<PGDetails />} />
            <Route path="locality-details" element={<LocalityDetails />} />
            <Route path="amenities" element={<Amenities />} />
            <Route path="gallary" element={<Gallary />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}
