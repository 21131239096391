

import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import AuthStyle from "../helper/AuthStyle";
import { validateEmail, validatePassword } from "../config/constant";
import { getLoginDetail } from "../config/api";

export default function Login() {
  const [navigate, setNavigate] = React.useState(false);
  const valSchema = {
    email: "",
    password: "",
  };
  const [auth, setAuth] = useState({ ...valSchema });
  const [error, setError] = useState({ ...valSchema });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState("");
  const [type, setType] = useState("password");
  const handleChange = (e, name) => {
    if (name === "password" && e.target.value.length >= 17) return false;
    setAuth({ ...auth, [name]: e.target.value });
  };
  const handleSignIn = () => {
    if (validateForm()) {
      getLoginDetail({
        UserName: auth.email,
        Password: auth.password,
      }).then((res) => {debugger
        if (res.length > 0) {
          sessionStorage.setItem("UserName", res[0].UserName);
          sessionStorage.setItem("FullName", res[0].FullName);
          sessionStorage.setItem("UserID", res[0].ID);
          setTimeout(() => (window.location.pathname = "/app/dashboard"), 1000);
        } else {
          setAlert("User Not Found");
          setTimeout(() => setLoading(false), 1000);
        }
      });
    }
  };
  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      handleSignIn();
    }
  };
  const validateForm = () => {
    let tempObj = { ...valSchema };
    let isValid = true;
    setLoading(true);
    if (auth.email === "" || !validateEmail(auth.email)) {
      tempObj.email = "Please enter valid email";
      isValid = false;
      setLoading(false);
    } else {
      tempObj.email = "";
    }
    if (auth.password === "" || !validatePassword(auth.password)) {
      tempObj.password =
        "Please enter at least 1 number & special character. password length should be 8 to 16 characters";
      isValid = false;
      setLoading(false);
    } else {
      tempObj.password = "";
    }
    setError(tempObj);
    return isValid;
  };
  
  return (
    <>
      <AuthStyle />
      <div className="container-xxl">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner">
            <div className="card">
              <div className="card-body">
                <div className="app-brand justify-content-center mb-4">
                  {/* <img src="/logo-lg.png" width={120} alt="" /> */}
                </div>
                <h4 className="mb-4 text-center">Welcome to Dream Estates Login!</h4>

                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email or Username
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email-username"
                    value={auth.email}
                    onChange={(e) => handleChange(e, "email")}
                    onKeyDown={handleKeypress}
                    placeholder="Enter your email or username"
                    autoFocus
                  />
                </div>
                <div className="mb-3 form-password-toggle">
                  <div className="d-flex justify-content-between">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                    <a href="/auth/forgot-password" tabIndex={-1}>
                      <small>Forgot Password?</small>
                    </a>
                  </div>
                  <div className="input-group input-group-merge">
                    <input
                      type={type}
                      id="password"
                      className="form-control"
                      name="password"
                      value={auth.password}
                      onChange={(e) => handleChange(e, "password")}
                      onKeyDown={handleKeypress}
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      aria-describedby="password"
                    />
                    <span className="input-group-text cursor-pointer"
                      onClick={() => { type === "password"?setType("text"):setType("password");console.log(type) }}>
                      {type === "password" ?
                        <i className="bx bx-hide"></i> : <i className="bx bx-show"></i>
                      }
                    </span>
                  </div>
                </div>
                <div className="mb-3 pt-2">
                  {/* <button
                    className="btn btn-primary d-grid w-100"
                    onClick={() => setNavigate(true)}
                  >
                    Sign in
                  </button> */}
                  {!loading ? (
                    <button
                      className="btn btn-lg w-100 btn-primary mb-3"
                      style={{ fontSize: 16 }}
                      onClick={handleSignIn}
                    >
                      Sign in
                    </button>
                  ) : (
                    <button
                      className="btn btn-lg w-100 btn-primary mb-3 _lding"
                      style={{ fontSize: 16 }}
                    >
                      loading...
                    </button>
                  )}
                  {alert !== "" && loading === false ? (
                    <div className="alert alert-danger">
                      <span>{alert}</span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {navigate && <Navigate to="/app/dashboard" replace={true} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}








