//import React from "react";
import FormControl from "../../../components/FormControl";
import { Row, Col, Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  iuamenities,
  getAmenitiesForDDL,
  getAmenitiesDetail,
  getAmenitiesDelete,
  getAmenitiesDetailByID,
  useQuery,
  getAmenityDetail,
  iuamenitymaster,
} from "../../../config/api";
import { useNavigate, useLocation } from "react-router-dom";

function Amenities({ setSmShow, editID }) {
  let location = useLocation();
  let navigate = useNavigate();
  let query = useQuery();
  const [amenity, setAmenity] = useState([]);
  const [amenityName, setAmenityName] = useState("");
  //const propertydetailid = query.get("id");

  // const id = query.get("id");
  const id = query.get("id");
  const [state, setState] = useState({
    AmenitiesID: "0",
    PropertydetailID: id,
    Bedroom: "",
    Bathroom: "",
    Balcony: "",
    Floor: "",
    Flats: "",
    Parking: "",
    OtherFeature: "",
    CreatedBy: sessionStorage.getItem("UserID"),
    errors: [],
  });
  const [feature, setFeature] = useState([]);
  // const id = query.get("id");
  // const id = editID;
  useEffect(() => {
    // BindEducationDDL();
    getAmenity();
    if (
      id !== "" &&
      id !== null &&
      id !== "0" &&
      id !== 0 &&
      typeof id !== "undefined"
    ) {
      UpdateData(id);
    }
  }, []);

  const bedroomoptions = [
    { value: "1", text: "1" },
    { value: "2", text: "2" },
    { value: "3", text: "3" },
    { value: "4", text: "4" },
    { value: "5", text: "5" },
  ];

  const bathroomoptions = [
    { value: "1", text: "1" },
    { value: "2", text: "2" },
    { value: "3", text: "3" },
    { value: "4", text: "4" },
    { value: "5", text: "5" },
  ];

  const balconiesoptions = [
    { value: "1", text: "1" },
    { value: "2", text: "2" },
    { value: "3", text: "3" },
    { value: "4", text: "4" },
    { value: "5", text: "5" },
  ];

  const flooroptions = [
    { value: "1", text: "1" },
    { value: "2", text: "2" },
    { value: "3", text: "3" },
    { value: "4", text: "4" },
    { value: "5", text: "5" },
  ];

  const flatoptions = [
    { value: "1", text: "1" },
    { value: "2", text: "2" },
    { value: "3", text: "3" },
    { value: "4", text: "4" },
    { value: "5", text: "5" },
  ];

  const parkingoptions = [
    { value: "2- Wheeler", text: "2- Wheeler" },
    { value: "4- Wheeler", text: "4- Wheeler" },
  ];

  // function handlechange(e) {
  //   setState({ ...state, [e.target.name]: e.target.value });
  // }

  function handlechange(e) {
    if (e.target.name === "AvailableAmenities") {
      let temp = [];
      for (
        let i = 0;
        i < e.target.parentElement.parentElement.children.length;
        i++
      ) {
        if (
          e.target.parentElement.parentElement.children[i].children[0].checked
        ) {
          temp.push(
            e.target.parentElement.parentElement.children[i].children[0].value
          );
        }
      }
      setState({ ...state, [e.target.name]: temp.join(",") });
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  }
  function handlefeature(e) {
    let arr = [...feature];
    if (e.target.checked) {
      arr.push(e.target.value);
    } else {
      arr.pop(e.target.value);
    }
    setFeature(arr);
    setState({ ...state, OtherFeature: arr.toString() });
  }

  async function SaveData() {
    let errors = {};
    //window.location.href = "/app/package/add/itinerary?id=9";
    if (validate()) {
      await iuamenities(state)
        .then((response) => {
          if (response[0].ID !== "exists") {
            alert("Amenities Details Saved Successfully");
            ResetState();
            // window.location.reload(true);
            navigate(
              `/app/listing/add/gallary${location.search.includes("m=e") ? location.search : "?id=" + id
              }`
            );
          }
          //  else {
          //   errors["PropertyType"] = "This Property Type Already exists...";
          // }
          setState({
            ...state,
            errors: errors,
          });
        })
        .catch((error) => {
          alert(error);
        });
    }
  }
  async function SaveAmenity() {
    debugger
    let errors = {};
    //window.location.href = "/app/package/add/itinerary?id=9";
    if (amenityName !== "") {
      await iuamenitymaster({ ID: "0", Name: amenityName })
        .then((response) => {
          if (response[0].ID !== "exists") {
            alert("Name Saved Successfully");
            setAmenityName("");
            getAmenity();
          }
          else {
            alert("amenity name already exists...");
          }
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      alert("Please enter amenity name");
    }
  }

  async function UpdateData(PropertydetailID) {
    await getAmenitiesDetailByID(PropertydetailID)
      .then((response) => {
        if (response !== "" && response?.length > 0) {
          setState(response[0]);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }
  async function getAmenity() {
    await getAmenityDetail()
      .then((response) => {
        if (response !== "" && response?.length > 0) {
          let a = [];
          for (let i = 0; i < response.length; i++) {
            a.push(response[i].Name);
          }
          setAmenity(a);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }
  async function ResetState() {
    setState({
      AmenitiesID: "0",
      PropertydetailID: id,
      Bedroom: "",
      Bathroom: "",
      Balcony: "",
      Floor: "",
      Flats: "",
      Parking: "",
      OtherFeature: "",
      CreatedBy: sessionStorage.getItem("UserID"),
      errors: [],
    });
  }

  function validate() {
    let errors = {};
    let IsValid = true;

    if (!state?.Bedroom) {
      IsValid = false;
      errors["Bedroom"] = "Bedroom  is Required";
    }
    if (!state?.Bathroom) {
      IsValid = false;
      errors["Bathroom"] = "Bathroom is Required";
    }
    if (!state?.Balcony) {
      IsValid = false;
      errors["Balcony"] = "Balcony is Required";
    }
    if (!state?.Floor) {
      IsValid = false;
      errors["Floor"] = "Floor is Required";
    }
    if (!state?.Flats) {
      IsValid = false;
      errors["Flats"] = "Flats is Required";
    }
    if (!state?.Parking) {
      IsValid = false;
      errors["Parking"] = "Parking is Required";
    }

    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  }

  console.log(state);

  return (
    <>
      <div className="row g-3">

        <Col lg={6} md={12} sm={12}>
          <FormControl
            wrapperClass=""
            label="Bedrooms"
            type="select"
            data-name="bedrooms"
            name="Bedroom"
            value={state.Bedroom}
            onChange={handlechange}
            options={bedroomoptions}
          />
          {state.errors ? (
            <div className="text-red">{state.errors.Bedroom}</div>
          ) : (
            ""
          )}
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormControl
            wrapperClass=""
            label="Bathrooms"
            type="select"
            data-name="bathrooms"
            name="Bathroom"
            value={state.Bathroom}
            onChange={handlechange}
            options={bathroomoptions}
          />
          {state.errors ? (
            <div className="text-red">{state.errors.Bathroom}</div>
          ) : (
            ""
          )}
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormControl
            wrapperClass=""
            label="Balconies"
            type="select"
            data-name="balconies"
            name="Balcony"
            value={state.Balcony}
            onChange={handlechange}
            options={balconiesoptions}
          />
          {state.errors ? (
            <div className="text-red">{state.errors.Balcony}</div>
          ) : (
            ""
          )}
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormControl
            wrapperClass=""
            label="Floor No."
            type="select"
            data-name="floor"
            name="Floor"
            value={state.Floor}
            onChange={handlechange}
            options={flooroptions}
          />
          {state.errors ? (
            <div className="text-red">{state.errors.Floor}</div>
          ) : (
            ""
          )}
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormControl
            wrapperClass=""
            label="Total Flats"
            type="select"
            data-name="flats"
            name="Flats"
            value={state.Flats}
            onChange={handlechange}
            options={flatoptions}
          />
          {state.errors ? (
            <div className="text-red">{state.errors.Flats}</div>
          ) : (
            ""
          )}
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormControl
            wrapperClass=""
            label="Parking"
            type="select"
            data-name="parking"
            name="Parking"
            value={state.Parking}
            onChange={handlechange}
            options={parkingoptions}
          />
          {state.errors ? (
            <div className="text-red">{state.errors.Parking}</div>
          ) : (
            ""
          )}
        </Col>

        <span>Other Featurs</span>
        <Row>
          {amenity && amenity.map((item, index) => (
            <Col lg={4} md={12} sm={12} key={index}>
              <Form>
                <Form.Check
                  inline
                  label={item}
                  value={item}
                  onChange={handlefeature}
                  checked={state.OtherFeature.includes(item) ? true : false}
                />
              </Form>
            </Col>
          ))}
          {/* <Col lg={4} md={12} sm={12}>
            <Form>
              <Form.Check
                inline
                label="Lawn"
                value="Lawn"
                onChange={handlefeature}
                checked={state.OtherFeature.includes("Lawn") ? true : false}
              />
            </Form>
          </Col>
          <Col lg={4} md={12} sm={12}>
            <Form>
              <Form.Check
                inline
                label="Gym"
                value="Gym"
                onChange={handlefeature}
                checked={state.OtherFeature.includes("Gym") ? true : false}
              />
            </Form>
          </Col>
          <Col lg={4} md={12} sm={12}>
            <Form>
              <Form.Check
                inline
                label="Swimming Pool"
                value="Swimming Pool"
                onChange={handlefeature}
                checked={
                  state.OtherFeature.includes("Swimming Pool") ? true : false
                }
              />
            </Form>
          </Col>
          <Col lg={4} md={12} sm={12}>
            <Form>
              <Form.Check
                inline
                label="Meditation Zone"
                value="Meditation Zone"
                onChange={handlefeature}
                checked={
                  state.OtherFeature.includes("Meditation Zone") ? true : false
                }
              />
            </Form>
          </Col>
          <Col lg={4} md={12} sm={12}>
            <Form>
              <Form.Check
                inline
                label="Outdoor & Indoor Spoarts Area"
                value="Outdoor & Indoor Spoarts Area"
                onChange={handlefeature}
                checked={
                  state.OtherFeature.includes("Outdoor & Indoor Spoarts Area")
                    ? true
                    : false
                }
              />
            </Form>
          </Col>
          <Col lg={4} md={12} sm={12}>
            <Form>
              <Form.Check
                inline
                label="Spa & Steam room"
                value="Spa & Steam room"
                onChange={handlefeature}
                checked={
                  state.OtherFeature.includes("Spa & Steam room") ? true : false
                }
              />
            </Form>
          </Col>
          <Col lg={4} md={12} sm={12}>
            <Form>
              <Form.Check
                inline
                label="Library & Classroom"
                value="Library & Classroom"
                onChange={handlefeature}
                checked={
                  state.OtherFeature.includes("Library & Classroom")
                    ? true
                    : false
                }
              />
            </Form>
          </Col>
          <Col lg={4} md={12} sm={12}>
            <Form>
              <Form.Check
                inline
                label="Party Lawn & Hall"
                value="Party Lawn & Hall"
                onChange={handlefeature}
                checked={
                  state.OtherFeature.includes("Party Lawn & Hall")
                    ? true
                    : false
                }
              />
            </Form>
          </Col>
          <Col lg={4} md={12} sm={12}>
            <Form>
              <Form.Check
                inline
                label="Lounge, Theater or Cafeteria"
                value="Lounge, Theater or Cafeteria"
                onChange={handlefeature}
                checked={
                  state.OtherFeature.includes("Lounge, Theater or Cafeteria")
                    ? true
                    : false
                }
              />
            </Form>
          </Col> */}
        </Row>
        <Row>
          <Col lg={6} md={12} sm={12}>
            <FormControl
              wrapperClass=""
              label="Add More Aminities"
              type="text"
              data-name="addMoreAminities"
              name="addMoreAminities"
              onChange={(e) => { setAmenityName(e.target.value) }}
            />
          </Col>
          <Col lg={4} md={12} sm={12} className="my-1">
            <button className="btn btn-primary btn-next d-flex align-items-center mt-4"
              onClick={SaveAmenity}>
              <i className="bx bx-plus bx-sm ml-sm-n2"></i>
              <span className="align-middle d-sm-inline-block d-none me-sm-1">
                Add More
              </span>
            </button>
          </Col>
        </Row>
        <div className="col-12">
          <div className="text-center">
            {/* <button onClick={ResetState} className="btn btn-warning me-3">reset</button>
            <button onClick={SaveData} className="btn btn-primary">submit</button> */}

            <div className="col-12 d-flex justify-content-between mt-5">
              <button
                className="btn btn-label-secondary btn-prev d-flex align-items-center"
                onClick={() => {
                  navigate(
                    `/app/listing/add/locality-details${location.search}`
                  );
                }}
              >
                <i className="bx bx-chevron-left bx-sm ms-sm-n2"></i>
                <span className="align-middle d-sm-inline-block d-none">
                  Previous
                </span>
              </button>
              <button
                onClick={SaveData}
                className="btn btn-primary btn-next d-flex align-items-center"
              >
                <span className="align-middle d-sm-inline-block d-none me-sm-1">
                  Next
                </span>
                <i className="bx bx-chevron-right bx-sm me-sm-n2"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Amenities;
