import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../components/table/Index";
import { getUserDetail } from "../../config/api";

function SellerUserDetails() {
  const navigate = useNavigate();
  // const [data, setData] = useState([
  //   {
  //     name: "vidya",
  //     email: "vidya@gmail.com",
  //     mobile: "76823792",
  //   },
  // ]);
  const [data, setData] = useState([]);
  const [propertydata, setPropertyData] = useState([]);
  useEffect(() => {
    //check_islogin();
    bindData();
  }, []);
  
  async function bindData() {
    await getUserDetail({Type:'seller'})
      .then((response) => {
        if (response.length > 0) {
          setData(response);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }
  
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email ID",
        accessor: "email",
      },
      {
        Header: "Mobile Number",
        accessor: "mobile",
      },
      {
        Header: "action",
        Cell: ({ row }) => {
          return (
            <div className="d-flex">
              <button
                className="border-0 bg-transparent text-primary d-flex align-items-center me-3"
                onClick={() => {
                  navigate(`/app/uproperty-details?t=seller&id=${row.original.id}`);
                }}
              >
                View
              </button>
              
              <button
                className="border-0 bg-transparent text-primary d-flex align-items-center me-3"
                onClick={() => {
                  navigate(`/app/verified-uproperty-details?t=seller&id=${row.original.id}`);
                }}
              >
                Verified
              </button>
            </div>
          );
        },

        accessor: "action",
      },
    ],
    []
  );
  return (
    <>
      <div>
        <h4 className="fw-bold py-3 mb-4">
          <span className="text-muted fw-light">Home/</span> Seller User Details
        </h4>

        <div className="card">
          <div className="card-datatable table-responsive">
            <div className="dataTables_wrapper dt-bootstrap5 no-footer">
              {data ? <Table columns={columns} data={data} /> : <></>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SellerUserDetails;
