//import React from 'react'
import FormControl from "../../../components/FormControl";
import React, { useEffect, useState } from "react";
import {
  iulocalitydetail,
  getLocalitydetailForDDL,
  getLocalitydetailDetail,
  getLocalitydetailDelete,
  getLocalitydetailDetailByID,
  getCityForDDL,
  useQuery,
  getCountryForDDL,
  getStateForDDLByCountryID,
  getCityForDDLByStateID,
} from "../../../config/api";
import { useNavigate, useLocation } from "react-router-dom";
import { check_islogin } from "../../../config/constant";
import { Col } from "react-bootstrap";

function LocalityDetails({ setSmShow, editID }) {
  let location = useLocation();
  let navigate = useNavigate();
  let query = useQuery();

  //const propertydetailid = query.get("id");

  const id = query.get("id");
  const [state, setState] = useState({
    LocalityID: "0",
    PropertydetailID: id,
    Address: "",
    SocietyName: "",
    Area: "",
    Pincode: "",
    // City: "",
    // State: "",
    // Country: "",
    CityID: "0",
    StateID: "0",
    CountryID: "0",
    CreatedBy: sessionStorage.getItem("UserID"),
    errors: [],
  });

  const [countryddl, setCountryDDL] = useState([]);
  const [stateddl, setStateDDL] = useState([]);
  const [cityddl, setCityDDL] = useState([]);

  // const id = query.get("id");
  useEffect(() => {
    BindCountryDDL();
    // BindEducationDDL();
    if (
      id !== "" &&
      id !== null &&
      id !== "0" &&
      id !== 0 &&
      typeof id !== "undefined"
    ) {
      UpdateData(id);
    }
  }, []);

  console.log(state);

  const cityoptions = [
    { value: "JAL", text: "Jalgaon" },
    { value: "PUNE", text: "Pune" },
    { value: "MUM", text: "Mumbai" },
    { value: "DH", text: "Dhule" },
    { value: "AUN", text: "Aurangabad" },
  ];
  const localityoptions = [
    { value: "AL", text: "Apartment" },
    { value: "HL", text: "House" },
    { value: "FL", text: "Flat" },
    { value: "RHL", text: "Row House" },
    { value: "UAL", text: "Village" },
    { value: "CL", text: "City" },
  ];
  // const [propertydetailddl, setPropertyDetailDDL] = useState([]);
  // const [bhktypeddl, setBHKTypeDDL] = useState([]);
  // const [availabilityddl, setAvailabilityDDL] = useState([]);
  // const [preferredtenantddl, setPreferredTenantDDL] = useState([]);
  // const [furnishingddl, setFurnishingDDL] = useState([]);
  // const [parkingddl, setParkingDDL] = useState([]);
  // const [cityddl, setCityDDL] = useState([]);

  // useEffect(() => {
  //   //check_islogin();

  //   // if (query.get("id")) {
  //   //   getData(query.get("id"));
  //   // }
  // }, []);

  // function handlechange(e) {
  //   setState({ ...state, [e.target.name]: e.target.value });
  // }
  function handlechange(e) {
    if (e.target.name === "CountryID") {
      BindStateDDLByCountryID(e.target.value);
    }
    if (e.target.name === "StateID") {
      BindCityDDLByStateID(e.target.value);
    }
    setState({ ...state, [e.target.name]: e.target.value });
  }

  // function handlechangecity(e) {
  //   const a = Array.isArray(e) ? e.map((x) => x.value) : [];
  //   setState({ ...state, City: a.toString() });
  //   setCityselect(a);
  // }

  async function SaveData() {
    let errors = {};

    //window.location.href = "/app/package/add/itinerary?id=9";
    if (validate()) {
      await iulocalitydetail(state)
        .then((response) => {
          if (response.ID !== "exists") {
            alert("Locality Details Saved Successfully");
            ResetState();
            // window.location.reload(true);
            navigate(
              `/app/listing/add/amenities${
                location.search.includes("m=e") ? location.search : "?id=" + id
              }`
            );
          }
          //else {
          //   errors["PropertyType"] = "This Property Type Already exists...";
          // }
          setState({
            ...state,
            errors: errors,
          });
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  async function BindCountryDDL() {
    await getCountryForDDL(sessionStorage.getItem("UserID"))
      .then((response) => {
        setCountryDDL(response[0]);
      })
      .catch((error) => {
        alert(error);
      });
  }
  async function BindStateDDLByCountryID(CountryID) {
    await getStateForDDLByCountryID(CountryID)
      .then((response) => {
        setStateDDL(response[0]);
      })
      .catch((error) => {
        alert(error);
      });
  }
  async function BindCityDDLByStateID(StateID) {
    await getCityForDDLByStateID(StateID)
      .then((response) => {
        setCityDDL(response[0]);
      })
      .catch((error) => {
        alert(error);
      });
  }

  console.log(state);

  async function UpdateData(PropertydetailID) {
    await getLocalitydetailDetailByID(PropertydetailID)
      .then((response) => {
        if (response !== "" && response?.length > 0) {
          BindStateDDLByCountryID(response[0].CountryID);
          BindCityDDLByStateID(response[0].StateID);
          setState(response[0]);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  async function ResetState() {
    setState({
      LocalityID: "0",
      PropertydetailID: id,
      Address: "",
      SocietyName: "",
      Area: "",
      Pincode: "",
      CityID: "0",
      StateID: "0",
      CountryID: "0",
      // City: "",
      // State: "",
      // Country: "",
      CreatedBy: sessionStorage.getItem("UserID"),
      errors: [],
    });
  }

  function validate() {
    let errors = {};
    let IsValid = true;

    if (!state?.Address) {
      IsValid = false;
      errors["Address"] = "Address Name is Required";
    }
    if (!state?.SocietyName) {
      IsValid = false;
      errors["SocietyName"] = "Society Name is Required";
    }
    if (!state?.Area) {
      IsValid = false;
      errors["Area"] = "Area is Required";
    }
    if (!state?.Pincode) {
      IsValid = false;
      errors["Pincode"] = "Pincode is Required";
    }
    if (!state?.CityID) {
      IsValid = false;
      errors["CityID"] = "City Name is Required";
    }
    if (!state?.StateID) {
      IsValid = false;
      errors["StateID"] = "State is Required";
    }
    if (!state?.CountryID) {
      IsValid = false;
      errors["CountryID"] = "Country is Required";
    }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  }

  console.log(state);

  return (
    <>
      <div className="row g-3">
        <Col lg={6} md={12} sm={12}>
          {" "}
          <FormControl
            wrapperClass=""
            type="text"
            label="Address"
            data-name=""
            name="Address"
            placeholder="Enter Address"
            value={state.Address}
            onChange={handlechange}
          />
          {state.errors ? (
            <div className="text-red">{state.errors.Address}</div>
          ) : (
            ""
          )}
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormControl
            wrapperClass=""
            type="text"
            label="Society Name"
            data-name=""
            name="SocietyName"
            value={state.SocietyName}
            onChange={handlechange}
            placeholder="Enter Society Name"
          />
          {state.errors ? (
            <div className="text-red">{state.errors.SocietyName}</div>
          ) : (
            ""
          )}
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormControl
            wrapperClass=""
            type="text"
            label="Area / Locality"
            data-name=""
            name="Area"
            value={state.Area}
            onChange={handlechange}
            placeholder="Enter Area"
          />
          {state.errors ? (
            <div className="text-red">{state.errors.Area}</div>
          ) : (
            ""
          )}
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormControl
            wrapperClass=""
            type="text"
            label="Pincode"
            data-name=""
            name="Pincode"
            value={state.Pincode}
            onChange={handlechange}
            placeholder="Enter Pincode"
          />
          {state.errors ? (
            <div className="text-red">{state.errors.Pincode}</div>
          ) : (
            ""
          )}
        </Col>

        <Col lg={6} md={12} sm={12}>
          {/* <div className="mb-2"> */}
          <label
            for="Country"
            className="block mb-1 text-sm font-medium text-black text-left"
          >
            Country
          </label>
          {/* <input
              type="text"
              id="Country"
              className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
              placeholder=" "
              data-name=""
              name="Country"
              value={state.Country}
              onChange={handlechange}
            /> */}
          <select
            id="CountryID"
            className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
            wrapperClass=""
            onChange={handlechange}
            value={state.CountryID}
            data-name="CountryID"
            name="CountryID"
          >
            <option className="text-lg">Select</option>
            {/* {widthoptions.map((item, idx) => (
              <option value={item.text} key={idx}>
                {item.text}
              </option>
            ))} */}
            {countryddl?.map((item, idx) => (
              <option key={idx} value={item.CountryID}>
                {" "}
                {item.CountryName}
              </option>
            ))}
          </select>
          {/* {state.errors ? (
            <div className="text-red-600 text-left text-xs">{state.errors.CountryID}</div>
          ) : (
            ""
          )} */}
          {state.errors ? (
            <div className="text-red">{state.errors.CountryID}</div>
          ) : (
            ""
          )}
          {/* </div> */}
        </Col>
        <Col lg={6} md={12} sm={12}>
          {/* <div className="mb-2"> */}
          <label
            for="State"
            className="block mb-1 text-sm font-medium text-black text-left"
          >
            State
          </label>
          {/* <input
              type="text"
              id="State"
              className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
              placeholder=" "
             
              name="State"
              value={state.State}
              onChange={handlechange}
            /> */}
          <select
            id="StateID"
            className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
            onChange={handlechange}
            value={state.StateID}
            data-name="StateID"
            name="StateID"
          >
            <option className="text-lg">Select</option>
            {/* {widthoptions.map((item, idx) => (
              <option value={item.text} key={idx}>
                {item.text}
              </option>
            ))} */}
            {stateddl?.map((item, idx) => (
              <option key={idx} value={item.StateID}>
                {" "}
                {item.StateName}
              </option>
            ))}
          </select>
          {state.errors ? (
            <div className="text-red-600 text-left text-xs">
              {state.errors.StateID}
            </div>
          ) : (
            ""
          )}
          {/* </div> */}
        </Col>

        <Col lg={6} md={12} sm={12}>
          {/* <div className="mb-2"> */}
          <label
            for="City"
            className="block mb-1 text-sm font-medium text-black text-left"
          >
            City
          </label>
          {/* <input
              type="text"
              id="City"
              className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
              placeholder=" "
            
              name="City"
              value={state.City}
              onChange={handlechange}
            /> */}
          <select
            id="CityID"
            className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
            onChange={handlechange}
            value={state.CityID}
            data-name="CityID"
            name="CityID"
          >
            <option className="text-lg">Select</option>
            {/* {widthoptions.map((item, idx) => (
              <option value={item.text} key={idx}>
                {item.text}
              </option>
            ))} */}
            {cityddl?.map((item, idx) => (
              <option value={item.CityID} key={idx}>
                {item.CityName}
              </option>
            ))}
          </select>
          {state.errors ? (
            <div className="text-red-600 text-left text-xs">
              {state.errors.CityID}
            </div>
          ) : (
            ""
          )}
          {/* </div> */}
        </Col>
      </div>
      {/* <Col lg={6} md={12} sm={12}>
          <FormControl
            wrapperClass=""
            label="CityID"
            type="select"
            data-name="CityID"
            name="CityID"
            value={state.CityID}
            onChange={handlechange}
            options={cityoptions}
          />
          {state.errors ? (
            <div className="text-red">{state.errors.City}</div>
          ) : (
            ""
          )}
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormControl
            wrapperClass=""
            type="text"
            label="State"
            data-name="State"
            name="State"
            value={state.State}
            placeholder="Enter State"
            onChange={handlechange}
          />
          {state.errors ? (
            <div className="text-red">{state.errors.State}</div>
          ) : (
            ""
          )}
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormControl
            wrapperClass=""
            type="text"
            label="Country"
            data-name=""
            name="Country"
            value={state.Country}
            placeholder="Enter Country"
            onChange={handlechange}
          />
          {state.errors ? (
            <div className="text-red">{state.errors.Country}</div>
          ) : (
            ""
          )}
        </Col> */}
      {/* <FormControl
          wrapperClass=""
          label="State"
          type="select"
          data-name="State"
          name="State"
          value={state.State}
          onChange={handlechange}
          options={localityoptions}
          error={
            state.errors
              ? state.errors.State
                ? state.errors.State
                : ""
              : ""
          }
        /> */}

      <div className="col-12">
        <div className="text-center">
          {/* <button onClick={ResetState} className="btn btn-warning me-3">reset</button>
              <button onClick={SaveData} className="btn btn-primary">submit</button> */}

          <div className="col-12 d-flex justify-content-between mt-5">
            <button
              className="btn btn-label-secondary btn-prev d-flex align-items-center"
              onClick={() => {
                navigate(`/app/listing/add/property-details${location.search}`);
              }}
            >
              <i className="bx bx-chevron-left bx-sm ms-sm-n2"></i>
              <span className="align-middle d-sm-inline-block d-none">
                Previous
              </span>
            </button>
            <button
              onClick={SaveData}
              className="btn btn-primary btn-next d-flex align-items-center"
            >
              <span className="align-middle d-sm-inline-block d-none me-sm-1">
                Next
              </span>
              <i className="bx bx-chevron-right bx-sm me-sm-n2"></i>
            </button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default LocalityDetails;
