//import React from "react";
import FormControl from "../../../components/FormControl";
import { Form } from "react-bootstrap";
import React , { useEffect, useState }from "react";
import {
  iurentaldetail,
  getRentaldetailForDDL,
  getRentaldetailDetail,
  getRentaldetailDelete,
  getRentaldetailDetailByID,
  useQuery,
 } from "../../../config/api";
 import { useNavigate, useLocation } from "react-router-dom";

function RentalDetails({ setSmShow, editID }) {

  let location = useLocation();
  let navigate = useNavigate();
  let query = useQuery();

// const id = query.get("id");
const id = query.get("id");
  const [state, setState] = useState({
    RentalPropertyID: "0",
    PropertydetailID:id,
    RentalPropertyType: "",
    ExpectedRent:"",
    ExpectedDeposite:"",
    Maintainance:"",
    AvailableFrom:"",
    PreferredTenant:"",
    Furnishing:"",
    Parking:"",
    Description:"",
    CreatedBy: sessionStorage.getItem("UserID"),
    errors: [],
  });


  //const id = query.get("id");
  //const id = editID;
  useEffect(() => {
    // BindEducationDDL();
    if (id !== "" && id !== null && id !== "0" && id !== 0 && typeof id !== "undefined") {
      UpdateData(id);
    }
  }, []);

  console.log(state);

  const maintainceoptions = [
    { value: "Predetermined Maintenance", text: "Predetermined Maintenance" },
    { value: "Proactive Maintenance", text: "Proactive Maintenance" },
    { value: "Responsive Maintenance ", text: "Responsive Maintenance " },
    { value: "Emergency Maintenance", text: "Emergency Maintenance" },
    { value: "Facility Maintenance", text: "Facility Maintenance" },
  ];

  const preferedtenantoptions = [
    { value: "Rent Agreement", text: "Rent Agreement" },
    { value: "Lease Agreement", text: "Lease Agreement" },
    { value: "Leave Agreement", text: "Leave Agreement" },
    { value: "License Agreement", text: "License Agreement" },
  ];
  const furnishingoptions = [
    { value: "Fully Furnishing", text: "Fully Furnishing" },
    { value: "Semi Furnishing", text: "Semi Furnishing" },
    { value: "Fully Furnishing on Rent", text: "Fully Furnishing on Rent" },
    { value: "Semi Furnishing on Rent", text: "Semi Furnishing on Rent" },
  ];
  const parkingoptions = [
    { value: "YES", text: "YES" },
    { value: "NO", text: "NO" },
   
  ];


 


  // function handlechange(e) {
  //   setState({ ...state, [e.target.name]: e.target.value });
  // }
  function handlechange(e) {
    
    if (e.target.name === "Rule") {
      let temp = [];
      for(let i=0;i<e.target.parentElement.parentElement.children.length;i++){
      if (e.target.parentElement.parentElement.children[i].children[0].checked) {
        temp.push(e.target.parentElement.parentElement.children[i].children[0].value);
      }
    }
      setState({ ...state, [e.target.name]: temp.join(',') });
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  }
  console.log(state);

  async function SaveData() {
    let errors = {};
    //window.location.href = "/app/package/add/itinerary?id=9";
    if (validate()) {
      await iurentaldetail(state)
        .then((response) => {
          if (response.ID !== "exists") {
            alert("Rental Details Saved Successfully");
            ResetState();
          //  window.location.reload(true);
            navigate(
              `/app/listing/add/amenities${
                location.search.includes("m=e")
                  ? location.search
                  : "?id=" + id
              }`
            );
          }
          //  else {
          //   errors["PropertyType"] = "This Property Type Already exists...";
          // }
          setState({
            ...state,
            errors: errors,
          });
        })
        .catch((error) => {
          alert(error);
        });
    }
  }


  async function UpdateData(PropertydetailID) {
    await getRentaldetailDetailByID(PropertydetailID)
      .then((response) => {
        if (response !== "" && response?.length > 0) {
        setState(response[0]);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }


  
  async function ResetState() {
    setState({
      RentalPropertyID: "0",
      PropertydetailID:id,
    RentalPropertyType: "",
    ExpectedRent:"",
    ExpectedDeposite:"",
    Maintainance:"",
    AvailableFrom:"",
    PreferredTenant:"",
    Furnishing:"",
    Parking:"",
    Description:"",
      CreatedBy: sessionStorage.getItem("UserID"),
      errors: [],
    });
  }


  function validate() {
    let errors = {};
    let IsValid = true;

    if (!state?.RentalPropertyType) {
      IsValid = false;
      errors["RentalPropertyType"] = "Rental Property Type is Required";
    }
    if (!state?.ExpectedRent) {
      IsValid = false;
      errors["ExpectedRent"] = "Expected Rent is Required";
    }
    if (!state?.ExpectedDeposite) {
      IsValid = false;
      errors["ExpectedDeposite"] = "Expected Deposite is Required";
    }
    if (!state?.Maintainance) {
      IsValid = false;
      errors["Maintainance"] = "Maintainance is Required";
    }
    if (!state?.AvailableFrom) {
      IsValid = false;
      errors["AvailableFrom"] = "Available From is Required";
    }
    if (!state?.PreferredTenant) {
      IsValid = false;
      errors["PreferredTenant"] = "Preferred Tenant is Required";
    }
    if (!state?.Furnishing) {
      IsValid = false;
      errors["Furnishing"] = "Furnishing is Required";
    }
    if (!state?.Parking) {
      IsValid = false;
      errors["Parking"] = "Parking is Required";
    }
    if (!state?.Description) {
      IsValid = false;
      errors["Description"] = "Description is Required";
    }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  }



  console.log(state);



  return (
    <>
     <div className="row g-3">
        <span>PROPERTY AVAILABLE FOR</span>
        <Form>
          {["radio"].map((type) => (
            <div key={`inline-${type}`} className="mb-3">
              <Form.Check
                inline
                label="Only Rent"
                // name="group1"
                name="RentalPropertyType"
                type={type}
                id={`inline-${type}-1`}
                 value="Only Rent"
                onChange={handlechange}
                defaultChecked={state.RentalPropertyType === "Only Rent" ? true : false}
              />
              <Form.Check
                inline
                label="Only Lease"
                // name="group1"
                name="RentalPropertyType"
                type={type}
                id={`inline-${type}-2`}
                value="Only Lease"
                onChange={handlechange}
                defaultChecked={state.RentalPropertyType === "Only Lease" ? true : false}
              />
            </div>
          ))}
        </Form>
        <FormControl
          wrapperClass="col-sm-6"
          type="text"
          label="Expected Rent"
          data-name="expectedRent"
          placeholder="Enter Expected Rent"
          name="ExpectedRent"
          value={state.ExpectedRent}
          onChange={handlechange}
          error={
            state.errors
              ? state.errors.ExpectedRent
                ? state.errors.ExpectedRent
                : ""
              : ""
          }
        />
        <FormControl
          wrapperClass="col-sm-6"
          type="text"
          label="Expected Deposite"
          data-name="expectedDeposite"
          placeholder="Enter Expected Deposite"
          name="ExpectedDeposite"
          value={state.ExpectedDeposite}
          onChange={handlechange}
          error={
            state.errors
              ? state.errors.ExpectedDeposite
                ? state.errors.ExpectedDeposite
                : ""
              : ""
          }
        />
        <FormControl
          wrapperClass="col-sm-6"
          label="Maintainance"
          type="select"
          data-name="maintainance"
          name="Maintainance"
          value={state.Maintainance}
          onChange={handlechange}
          error={
            state.errors
              ? state.errors.Maintainance
                ? state.errors.Maintainance
                : ""
              : ""
          }
          options={maintainceoptions}
        />
        <FormControl
          wrapperClass="col-sm-6"
          label="Available From"
          type="date"
          data-name="available from"
          name="AvailableFrom"
          value={state.AvailableFrom}
          onChange={handlechange}
          error={
            state.errors
              ? state.errors.AvailableFrom
                ? state.errors.AvailableFrom
                : ""
              : ""
          }
        />
        <FormControl
          wrapperClass="col-sm-6"
          label="Preferred Tenant"
          type="select"
          data-name="preferred tenant"
          name="PreferredTenant"
          value={state.PreferredTenant}
          onChange={handlechange}
          error={
            state.errors
              ? state.errors.PreferredTenant
                ? state.errors.PreferredTenant
                : ""
              : ""
          }
          options={preferedtenantoptions}
        />
        <FormControl
          wrapperClass="col-sm-6"
          label="Furnishing"
          type="select"
          data-name="furnishing"
          name="Furnishing"
          value={state.Furnishing}
          onChange={handlechange}
          error={
            state.errors
              ? state.errors.Furnishing
                ? state.errors.Furnishing
                : ""
              : ""
          }
          options={furnishingoptions}
        />
        <FormControl
          wrapperClass="col-sm-6"
          label="Parking"
          type="select"
          data-name="parking"
          name="Parking"
          value={state.Parking}
          onChange={handlechange}
          error={
            state.errors
              ? state.errors.Parking
                ? state.errors.Parking
                : ""
              : ""
          }
          options={parkingoptions}
        />
          <FormControl
          wrapperClass="col-sm-12"
          label="Description"
          type="textbox"
          data-name="description"
          name="Description"
          value={state.Description}
          onChange={handlechange}
          error={
            state.errors
              ? state.errors.Description
                ? state.errors.Description
                : ""
              : ""
          }
        />
        <div className="col-12">
          <div className="text-center">
            {/* <button onClick={ResetState} className="btn btn-warning me-3">reset</button>
            <button onClick={SaveData} className="btn btn-primary">submit</button> */}

<div className="col-12 d-flex justify-content-between mt-5">
        <button
          className="btn btn-label-secondary btn-prev d-flex align-items-center"
          onClick={() => {
            navigate(`/app/listing/add/pg-details${location.search}`);
          }}
        >
          <i className="bx bx-chevron-left bx-sm ms-sm-n2"></i>
          <span className="align-middle d-sm-inline-block d-none">
            Previous
          </span>
        </button>
        <button
          onClick={SaveData}
          className="btn btn-primary btn-next d-flex align-items-center"
        >
          <span className="align-middle d-sm-inline-block d-none me-sm-1">
            Next
          </span>
          <i className="bx bx-chevron-right bx-sm me-sm-n2"></i>
        </button>
      </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default RentalDetails;
