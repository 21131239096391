import React, { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/table/Index";
import { getPropertydetailDelete, getPropertydetailDetail} from "../../../config/api";
import { check_islogin } from "../../../config/constant";

export default function ManageProperty() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const columns = useMemo(
    () => [
      {
        Header: "Property Code",
        accessor: "PropertydetailID",
      },
      {
        Header: "Property Name",
        accessor: "Name",
      },
      {
        Header: "action",
        Cell: ({ row }) => {
          return (
            <div className="d-flex">
              <button
                className="border-0 bg-transparent text-primary d-flex align-items-center me-3"
                onClick={() => {
                  navigate(
                    `/app/listing/add/property-details?id=${row.original.PropertydetailID}&m=e`
                  );
                }}
              >
                <i className="bx bx-plus me-1"></i> edit
              </button>
              <button
                className="border-0 bg-transparent text-danger d-flex align-items-center"
                onClick={() => {
                  DeleteData(row);
                }}
              >
                <i className="bx bx-trash me-1"></i> delete
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    //check_islogin();
    bindData();
  }, []);

  async function bindData() {
    await getPropertydetailDetail()
      .then((response) => {
        if (response.length > 0) {
          setData(response);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  async function DeleteData(row) {
    let _id = row.original.PropertydetailID;
    if (window.confirm("Are you sure to delete.?")) {
      await getPropertydetailDelete(_id)
        .then((response) => {
          alert("Deleted Successfully");
          bindData();
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  return (
    <>
      <h4 className="fw-bold py-0 mb-4">
        <span className="text-muted fw-light">Home/</span>Property Master -{" "}
        <small style={{ fontWeight: 500 }}>Manage Property</small>
      </h4>
      <div className="card">
        <div className="card-datatable table-responsive">
          <div className="dataTables_wrapper dt-bootstrap5 no-footer">
            {data ? <Table columns={columns} data={data} /> : <></>}
          </div>
        </div>
      </div>
    </>
  );
}
