//import React from "react";
import FormControl from "../../../components/FormControl";
import React, { useEffect, useState } from "react";
import {
  iupropertydetail,
  getPropertydetailForDDL,
  getPropertydetailDetail,
  getPropertydetailDelete,
  getPropertydetailDetailByID,
  useQuery,
} from "../../../config/api";
//import FormSwitch from "../../../components/FormSwitch";
//import Select from "react-select";
//import CreatableSelect from "react-select/creatable";
import { useNavigate, useLocation } from "react-router-dom";
import { check_islogin } from "../../../config/constant";
import { Col, Tab, Tabs } from "react-bootstrap";

function PropertyDetails({ setSmShow, editID }) {
  // const formElements = [
  //   {
  //     label: "Property Type",
  //     dataName: "PropertyType",
  //     type: "select",
  //   },
  //   {
  //     label: "BHK Type",
  //     dataName: "BHKType",
  //     type: "select",
  //   },
  //   {
  //     label: "Availability",
  //     dataName: "Availability",
  //     type: "select",
  //   },
  //   {
  //     label: "Preferred Tenant",
  //     dataName: "PreferredTenant",
  //     type: "select",
  //   },
  //   {
  //     label: "Furnishing",
  //     dataName: "Furnishing",
  //     type: "select",
  //   },
  //   {
  //     label: "Parking",
  //     dataName: "Parking",
  //     type: "select",
  //   },
  // ];
  const widthoptions = [
    { value: "meter", text: "meter" },
    { value: "sq/ft", text: "sq/ft" },
  ];
  let location = useLocation();
  let navigate = useNavigate();
  let query = useQuery();

  const [state, setState] = useState({
    PropertydetailID: "0",
    PropertyName: "",
    PropertyType: "",
    PlanType: "buy",
    BHKType: "",
    Availability: "",
    PreferredTenant: "",
    Furnishing: "",
    YearBuilt: "",
    PropertyPrice: "",
    PropertyPriceInfo: "",
    PropertySize: "",
    WidthOfFlat: "",
    CreatedBy: 0, // 0 means admin
    IsProject:'No',
    errors: [],
  });

  const id = query.get("id");
  useEffect(() => {
    // BindEducationDDL();
    if (
      id !== "" &&
      id !== null &&
      id !== "0" &&
      id !== 0 &&
      typeof id !== "undefined"
    ) {
      UpdateData(id);
    }
  }, []);

  const propertytypeoptions = [
    { value: "Apartment", text: "Apartment" },
    { value: "Independent House", text: "Independent House" },
    { value: "Plot", text: "Plot" },
    { value: "Studio", text: "Studio" },
    { value: "Villa", text: "Villa" },
    { value: "Agricultural Land", text: "Agricultural Land" },
    { value: "Other", text: "Other" },
  ];

  const propertytypeoptionscommercial = [
    { value: "Office Space", text: "Office Space" },
    { value: "Shop", text: "Shop" },
    { value: "Commercial Plot", text: "Commercial Plot" },
    { value: "Warehouse / Godown", text: "Warehouse / Godown" },
    { value: "Other", text: "Other" },
  ];

  const bhktypeoptions = [
    { value: "1", text: "1 RK" },
    { value: "2", text: "1 BHK" },
    { value: "3", text: "2 BHK" },
    { value: "4", text: "3 BHK" },
    { value: "5", text: "4 BHK" },
    { value: "6", text: "5+ BHK" },
  ];
  const availabilityoptions = [
    { value: "Immediately", text: "Immediately" },
    { value: "Within 15 Days", text: "Within 15 Days" },
    { value: "Within 30 Days", text: "Within 30 Days" },
    { value: "After 15 Days", text: "After 15 Days" },
  ];
  const preferredtenantoptions = [
    { value: "Family", text: "Family" },
    { value: "Bachelor", text: "Bachelor" },
    { value: "Company", text: "Company" },
  ];
  const furnishingoptions = [
    { value: "Furnished", text: "Furnished" },
    { value: "Semi Furnished", text: "Semi Furnished" },
    { value: "Unfurnished", text: "Unfurnished" },
  ];
  const parkingoptions = [
    { value: "2- Wheeler", text: "2- Wheeler" },
    { value: "4- Wheeler", text: "4- Wheeler" },
  ];

  // useEffect(() => {
  //   check_islogin();
  //   // BindCountryDDL();
  //   // BindWaariSelectPackageForDDL();
  //   // BindDepartureCityDDL();
  //   if (query.get("id")) {
  //     getData(query.get("id"));
  //   }
  // }, []);
  // async function getData(id) {
  //   await  getPropertydetailDetailByID(id)
  //     .then((response) => {
  //       if (response) {

  //       }
  //     })
  //     .catch((error) => {
  //       alert(error);
  //     });
  // }

  async function UpdateData(PropertydetailID) {
    await getPropertydetailDetailByID(PropertydetailID)
      .then((response) => {
        setState(response[0]);
        console.log(response[0])
      })
      .catch((error) => {
        alert(error);
      });
  }

  function handlechange(e) {
    setState({ ...state, [e.target.name]: e.target.value });
  }
  function handlechangeradio(event) {
    setState({...state,
      IsProject: event.target.value
    });
  }
  async function SaveData() {
    let errors = {};
    //window.location.href = "/app/package/add/itinerary?id=9";
    if (validate()) {
      await iupropertydetail(state)
        .then((response) => {
          
          if (response[0].ID !== "exists") {
            alert("Property Details Saved Successfully");
            ResetState();
            // window.location.reload(true);
            navigate(
              `/app/listing/add/locality-details${location.search.includes("m=e")
                ? location.search
                : "?id=" + response[0].ID
              }`
            );
          } else {
            //     errors["PropertyName"] = "This Property Name Already exists...";
            //   }
            //   setState({
            //     ...state,
            //     errors: errors,
            //   });
            // })
            //alert("This Property Name Already exists....");}
            let errors = {};
            errors["PropertyName"] = "This Property Name Already exists....";
            // }
            setState({
              ...state,
              errors: errors,
            });
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  async function ResetState() {
    setState({
      PropertydetailID: "0",
      PropertyName: "",
      PlanType: "",
      PropertyType: "",
      BHKType: "",
      Availability: "",
      PreferredTenant: "",
      Furnishing: "",
      YearBuilt: "",
      PropertyPrice: "",
      PropertyPriceInfo: "",
      PropertySize: "",
      WidthOfFlat: "",
      CreatedBy: sessionStorage.getItem("UserID"),
      IsProject:'No',
      errors: [],
    });
  }

  function validate() {
    let errors = {};
    let IsValid = true;

    if (!state?.PropertyType) {
      IsValid = false;
      errors["PropertyType"] = "Property Type is Required";
    }

    if (!state?.PropertyName) {
      IsValid = false;
      errors["PropertyName"] = "Property Name is Required";
    }
    if (!state?.BHKType) {
      IsValid = false;
      errors["BHKType"] = "BHK Type is Required";
    }
    if (!state?.Availability) {
      IsValid = false;
      errors["Availability"] = "Availability is Required";
    }
    if (!state?.PreferredTenant) {
      IsValid = false;
      errors["PreferredTenant"] = "Preferred Tenant Type is Required";
    }
    if (!state?.Furnishing) {
      IsValid = false;
      errors["Furnishing"] = "Furnishing is Required";
    }
    if (!state?.YearBuilt) {
      IsValid = false;
      errors["YearBuilt"] = "Year Built is Required";
    }
    if (!state?.PropertyPrice) {
      IsValid = false;
      errors["PropertyPrice"] = "Property Price is Required";
    }
    if (!state?.PropertyPriceInfo) {
      IsValid = false;
      errors["PropertyPriceInfo"] = "Property Price Info is Required";
    }
    if (!state?.PropertySize) {
      IsValid = false;
      errors["PropertySize"] = "Property Size is Required";
    }
    if (!state?.WidthOfFlat) {
      IsValid = false;
      errors["WidthOfFlat"] = "Width Of Flat is Required";
    }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  }
  console.log(state);

  return (
    <>
      <div className="row g-3">
        <div className="row g-3">
          <label>Is Project</label>
          <div className="radio">
            <label>
              <input
                type="radio"
                value="Yes"
                checked={state.IsProject==="Yes"?true:false}
                onChange={handlechangeradio}
              />
              Yes
            </label>
          </div>
          <div className="radio">
            <label>
              <input
                type="radio"
                value="No"
                checked={state.IsProject==="No"?true:false}
                onChange={handlechangeradio}
              />
              No
            </label>
          </div>
        </div>
        {state.IsProject === "No"?
        <Tabs
          defaultActiveKey={
            state.PlanType && state.PlanType !== "" ? state.PlanType : ""
          }
          id="uncontrolled-tab-example"
          className="mb-3"
          onClick={(e) => {
            setState({ ...state, PlanType: e.target.dataset.rrUiEventKey });
          }}
        >
          <Tab eventKey="buy" title="Buy">
            <FormControl
              wrapperClass=""
              label="Property Type"
              type="select"
              data-name="PropertyType"
              name="PropertyType"
              value={state.PropertyType}
              placeholder="Ex."
              onChange={handlechange}
              options={propertytypeoptions}
            />
            {state.errors ? (
              <div className="text-red">{state.errors.PropertyType}</div>
            ) : (
              ""
            )}
          </Tab>
          <Tab eventKey="rent" title="Rent">
            <FormControl
              wrapperClass=""
              label="Property Type"
              type="select"
              data-name="PropertyType"
              name="PropertyType"
              value={state.PropertyType}
              placeholder="Ex."
              onChange={handlechange}
              options={propertytypeoptions}
            />
            {state.errors ? (
              <div className="text-red">{state.errors.PropertyType}</div>
            ) : (
              ""
            )}
          </Tab>
          <Tab eventKey="commercial" title="Commercial">
            <FormControl
              wrapperClass=""
              label="Property Type"
              type="select"
              data-name="PropertyType"
              name="PropertyType"
              value={state.PropertyType}
              placeholder="Ex."
              onChange={handlechange}
              options={propertytypeoptionscommercial}
            />
            {state.errors ? (
              <div className="text-red">{state.errors.PropertyType}</div>
            ) : (
              ""
            )}
          </Tab>
        </Tabs>:""}

        <Col lg={6} sm={12} md={12}>
          <FormControl
            wrapperClass=""
            label="Property Name"
            type="text"
            data-name="PropertyName"
            name="PropertyName"
            value={state.PropertyName}
            onChange={handlechange}
          />
          {state.errors ? (
            <div className="text-red">{state.errors.PropertyName}</div>
          ) : (
            ""
          )}
        </Col>
        <Col lg={6} sm={6} md={6}>
          <FormControl
            wrapperClass=""
            label="Year Built"
            type="text"
            data-name="YearBuilt"
            name="YearBuilt"
            value={state.YearBuilt}
            onChange={handlechange}
          />
          {state.errors ? (
            <div className="text-red">{state.errors.YearBuilt}</div>
          ) : (
            ""
          )}
        </Col>
        <Col sm={12} lg={6} md={12}>
          <FormControl
            wrapperClass=""
            label="Property Price"
            type="text"
            data-name="PropertyPrice"
            name="PropertyPrice"
            value={state.PropertyPrice}
            onChange={handlechange}
          />
          {state.errors ? (
            <div className="text-red">{state.errors.PropertyPrice}</div>
          ) : (
            ""
          )}
        </Col>
        <Col lg={6} sm={12} md={12}>
          <FormControl
            wrapperClass=""
            label="Property Price Info"
            type="text"
            data-name="PropertyPriceInfo"
            name="PropertyPriceInfo"
            value={state.PropertyPriceInfo}
            onChange={handlechange}
          />
          {state.errors ? (
            <div className="text-red">{state.errors.PropertyPriceInfo}</div>
          ) : (
            ""
          )}
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormControl
            wrapperClass=""
            label="Property Size"
            type="text"
            data-name="PropertySize"
            name="PropertySize"
            value={state.PropertySize}
            onChange={handlechange}
          />
          {state.errors ? (
            <div className="text-red">{state.errors.PropertySize}</div>
          ) : (
            ""
          )}
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormControl
            wrapperClass=""
            label="Width of Flats"
            type="select"
            data-name="WidthOfFlat"
            name="WidthOfFlat"
            value={state.WidthOfFlat}
            onChange={handlechange}
            options={widthoptions}
          />
          {state.errors ? (
            <div className="text-red">{state.errors.WidthOfFlat}</div>
          ) : (
            ""
          )}
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormControl
            wrapperClass=""
            label="BHK Type"
            type="select"
            data-name="BHKType"
            name="BHKType"
            value={state.BHKType}
            onChange={handlechange}
            options={bhktypeoptions}
          />
          {state.errors ? (
            <div className="text-red">{state.errors.BHKType}</div>
          ) : (
            ""
          )}
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormControl
            wrapperClass=""
            label="Availability"
            type="select"
            data-name="Availability"
            name="Availability"
            value={state.Availability}
            onChange={handlechange}
            options={availabilityoptions}
          />
          {state.errors ? (
            <div className="text-red">{state.errors.Availability}</div>
          ) : (
            ""
          )}
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormControl
            wrapperClass=""
            label="Preferred Tenant"
            type="select"
            data-name="PreferredTenant"
            name="PreferredTenant"
            value={state.PreferredTenant}
            onChange={handlechange}
            options={preferredtenantoptions}
          />
          {state.errors ? (
            <div className="text-red">{state.errors.PreferredTenant}</div>
          ) : (
            ""
          )}
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormControl
            wrapperClass=""
            label="Furnishing"
            type="select"
            data-name="Furnishing"
            name="Furnishing"
            value={state.Furnishing}
            onChange={handlechange}
            options={furnishingoptions}
          />
          {state.errors ? (
            <div className="text-red">{state.errors.Furnishing}</div>
          ) : (
            ""
          )}
        </Col>
        
        <div className="col-12">
          <div className="text-center">
            {/* <button onClick={ResetState} className="btn btn-warning me-3">reset</button> */}
            {/* <button  onClick={SaveData} className="btn btn-primary">Save</button>

            <span className="align-middle d-sm-inline-block d-none me-sm-1">
              Next
            </span> */}

            <div className="col-12 d-flex justify-content-end mt-5">
              <button
                onClick={SaveData}
                className="btn btn-primary btn-next d-flex align-items-center"
              >
                <span className="align-middle d-sm-inline-block d-none me-sm-1">
                  Next
                </span>
                <i className="bx bx-chevron-right bx-sm me-sm-n2"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PropertyDetails;
